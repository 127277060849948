import React from "react";
import bannerWrap from "../Assets/Images/banner-wrap.png";
import findFriend from "../Assets/Images/find-friend.png";
import conversation from "../Assets/Images/conversation.png";
import callImg from "../Assets/Images/call-img.png";
import chatImg from "../Assets/Images/Chat_img.png";
import logo from "../Assets/Images/logo.png";
import communities from "../Assets/Images/communities.png";
import SubFooter from "../Components/SubFooter";
import { Helmet } from "react-helmet-async";
import ios from '../Assets/Images/ios.png'
import android from '../Assets/Images/android.png'

function Home() {
  return (
    <>
      <Helmet>
        <title>Home | GoldieTalk</title>
        <meta
            name="description"
            content="GoldieTalk enables seamless communication, ensuring that you never miss a moment or a chance to connect with friends. Stay linked, share stories, and create lasting friendships through the power of conversation."
        />
        <meta
            name="keywords"
            content="GoldieTalk, Goldies, friendships, ageing, social connection, engaging communities, communities, connections, seniors, find friends, have conversations, create community, vibrant communties, chat, call, messageStay linked, share stories, and create lasting friendships through the power of conversation"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:title" content="Home | GoldieTalk"/>
        <meta property="og:description" content="GoldieTalk enables seamless communication, ensuring that you never miss a moment or a chance to connect with friends. Stay linked, share stories, and create lasting friendships through the power of conversation." />
      </Helmet>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <h1>The Conversation Community</h1>
              <h2>
              Where Friends Are<span> Goldie</span>
              </h2>
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <p>
                    The social network where talk is free and abundant.
                    GoldieTalk is the place where today’s older adults can make
                    friends, engage in meaningful conversations, and find
                    community – all from the comfort of home
                  </p>
                  {/* <button type="button" className="btn btn-primary me-sm-3 me-0">
                    Join GoldieTalk
                  </button> */}
                  {/* <button type="button" className="btn watch-btn">
                    Watch Video <i className="fa-solid fa-caret-right fa-fade"></i>
                  </button> */}
                  <div className="d-flex justify-content-center">
                    <a href="https://apps.apple.com/in/app/goldietalk/id6451487106" target="_blank">
                      <div className="dwld-app ms">
                        <div className="me-2"><img src={ios} alt="ios"/></div>
                        <div className="dwl-btn">
                          <p>Download on</p>
                          <h6>App Store</h6>
                        </div>
                      </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.goldietalk.app" target="_blank">
                      <div className="dwld-app ms-sm-0">
                        <div className="me-2"><img src={android} alt="android"/></div>
                        <div className="dwl-btn">
                          <p>Get it on</p>
                          <h6>Play Store</h6>
                        </div>
                      </div>
                    </a>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap-img">
          <img src={bannerWrap} className="img-fluid" alt="home"/>
        </div>
      </section>

      <section className="feature-wrapper">
        <div className="container">
          <div className="box_parent">
            <div className="text-center">
              <h6>App Features</h6>
              <h3>How GoldieTalk Works</h3>
              <p className="mt-3 pb-4">
                Make friends who share your interests or live nearby.
                It’s free to create your account today
              </p>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="text-center">
                  <img src={findFriend} alt="Find Friends"/>
                  <h5>Find Friends</h5>
                  <p className="feature-text">
                    Build your profile by answering a few questions and connect
                    with others based on similar interests and your location
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="text-center">
                  <img src={conversation} alt="Have Conversations"/>
                  <h5>Have Conversations</h5>
                  <p className="feature-text">
                    GoldieTalk is the place for real and unlimited
                    conversations! Users can reach out others by texting and
                    talking one-on-one with friends
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="text-center">
                  <img src={findFriend} alt="Create Community"/>
                  <h5>Create Community</h5>
                  <p className="feature-text">
                    Do you have a passion for vintage cars, pickleball, or
                    gardening? Using GoldieTalk, you can join others within
                    communities or create one and invite others to join
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="friendship-wrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <img src={callImg} className="img-fluid" alt="Stay Connected"/>
            </div>
            <div className="col-lg-5">
              <div className="friendship-wrapper-text px-1 px-lg-4">
                <h5>Stay Connected Anytime, Anywhere</h5>
                <h3 className="">
                  Form Strong Friendship <br />
                Bonds
                </h3>
                <p>
                  Today’s older adults remember the time when talking was the best
                  way to communicate with friends. With GoldieTalk, people
                  experience crystal-clear voice calls and stay connected with
                  peers, no matter where they are or what language they speak.
                  GoldieTalk enables seamless communication, ensuring that you
                  never miss a moment or a chance to connect with friends
                </p>
                {/* <button type="button" className="btn btn-primary">
                  Get the App
                </button> */}
                 <div className="d-flex justify-content-lg-start justify-content-center">
                    <a href="https://apps.apple.com/in/app/goldietalk/id6451487106" target="_blank">
                      <div className="dwld-app ms">
                        <div className="me-2"><img src={ios} alt="ios"/></div>
                        <div className="dwl-btn">
                          <p>Download on</p>
                          <h6>App Store</h6>
                        </div>
                      </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.goldietalk.app" target="_blank">
                      <div className="dwld-app ms-sm-0">
                        <div className="me-2"><img src={android} alt="android"/></div>
                        <div className="dwl-btn">
                          <p>Get it on</p>
                          <h6>Play Store</h6>
                        </div>
                      </div>
                    </a>
              </div>
              </div>
            </div>
            <div className="col-lg-5  order-lg-0 order-1">
              <div className="friendship-wrapper-text mt-4 px-1 px-lg-4">
                <h5>Chat With Intention</h5>
                <h3 className="">
                  Engage in Meaningful <br />
                  Dialogue
                </h3>
                <p>
                  Unlock the joy of exciting communication with our
                  user-friendly text and call features. Today’s older adults can
                  connect with others by sending messages and making phone
                  calls. Stay linked, share stories, and create lasting
                  friendships through the power of conversation
                </p>
                {/* <button type="button" className="btn btn-primary">
                  Get the App
                </button> */}
                  <div className="d-flex justify-content-lg-start justify-content-center">
                    <a href="https://apps.apple.com/in/app/goldietalk/id6451487106" target="_blank">
                      <div className="dwld-app ms">
                        <div className="me-2"><img src={ios} alt="ios"/></div>
                        <div className="dwl-btn">
                          <p>Download on</p>
                          <h6>App Store</h6>
                        </div>
                      </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.goldietalk.app" target="_blank">
                      <div className="dwld-app ms-sm-0">
                        <div className="me-2"><img src={android} alt="android"/></div>
                        <div className="dwl-btn">
                          <p>Get it on</p>
                          <h6>Play Store</h6>
                        </div>
                      </div>
                    </a>
              </div>
              </div>
            </div>
            <div className="col-lg-7  order-lg-1 order-0">
              <img src={chatImg} className="img-fluid mt-4" alt="chat"/>
            </div>
          </div>
        </div>
      </section>

      <section className="conversation-wrapper">
        <div className="p-0">
          <div className="conversation-box text-center">
            <h6>Join the Conversation</h6>
            <h3>Explore Vibrant Communities</h3>
            <p>
              GoldieTalk encourages you to adventure with others from around the
              world who share your passions. Lives well-journeyed can describe
              their experiences through calls, texts, picture posts and internet
              links. Make good friends while also gaining new, valuable
              perspectives. Want to create a book-of-the-month club, start a
              senior weightlifting community, or become a virtual world
              traveler? Download GoldieTalk today and get busy with communities
            </p>
            <img src={communities} className="img-fluid" alt="Join the Conversation"/>
          </div>
        </div>
        <div className="container">
          <SubFooter />
        </div>
      </section>
    </>
  );
}

export default Home;
