import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import About from './Pages/About';
import Contact from './Pages/Contact';
import TermsOfService from './Pages/TermsOfService';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/termsofservice' element={<TermsOfService />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
