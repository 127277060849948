import React from "react";
import bannerRight from "../Assets/Images/banner-right.png";
import reactoutSide from "../Assets/Images/reactout-side.png";
import logo from "../Assets/Images/logo.png";
import { useForm } from "react-hook-form";
import md5 from "md5";
import axios from "axios";
import Swal from "sweetalert2";
import SubFooter from "../Components/SubFooter";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {

  const contactFormURL = process.env.REACT_APP_API_URL+"/contactus";
  const apiKey = process.env.REACT_APP_API_KEY;
  const recaptchaRef = React.useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const timestamp = Date.now();
  const key= timestamp+apiKey;
  const encryptedBackendKey = md5(key)


  const headers = {
    'X-Secret-Key': encryptedBackendKey,
  };

  const formSubmit = async (formData) => {
    const token = await recaptchaRef.current.executeAsync();
    try {
      const data = {...formData, ...{timestamp},...{token}}
      const response = await axios.post(contactFormURL, data, { headers });

    //   console.log(data);
      // console.log(response.data);
      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
          // text: 'Thank you for your submission.',
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error submitting the form",
          text: response.data.message,
          timer: 3000,
        });
      }

      reset();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error submitting the form",
        text: error,
        timer: 3000,
      });
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | GoldieTalk</title>
        <meta
            name="description"
            content="We'd love to hear from you! You can get in touch with our friendly team now."
        />
        <meta
            name="keywords"
            content="GoldieTalk, Goldies, friendships, ageing, social connection, communities, seniors, contact us, get in touch, reach out, need more help"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:title" content="Contact Us | GoldieTalk"/>
        <meta property="og:description" content="We'd love to hear from you! You can get in touch with our friendly team now." />
      </Helmet>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_SITE_KEY}
      />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="contact-banner-text">
                    <h1>Say Hello!</h1>
                    <p className="text-wrap">
                      We’d love to hear from you! You can get in touch with our
                      friendly team now
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contactbanner-inner-wrapper mt-lg-4 mt-2">
                    <div>
                      <a href="tel:8554225544">
                        <span className="material-symbols-outlined me-2">call</span>
                      </a>
                    </div>
                    <div className="px-0 px-md-3">
                      <a href="tel:8554225544">
                        <h6>Give Us a Call</h6>
                        <p className="bnr-cnt-text">(855) 422-5544</p>
                      </a>
                    </div>
                  </div>
                  <div className="contactbanner-inner-wrapper">
                    <div>
                      <span className="material-symbols-outlined me-2">mail</span>
                    </div>
                    <div className="px-0 px-md-3">
                      <h6>Send Us an Email</h6>
                      <p className="bnr-cnt-text">hello@GoldieTalk.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="contact-banner-bottom">
                <img src={bannerRight} className="img-fluid" alt="contact us"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="reachout-warapp-body">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="text-center">
                <h6>Reach Out!</h6>
                <h4>Don’t Hesitate to Connect Today!</h4>
                <p>
                If you need more help just fill out this form <br></br> and send. We usually respond within 24 hours
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={reactoutSide} className="img-fluid" alt="reach out"/>
            </div>
            <div className="col-md-6">
            <form onSubmit={handleSubmit(formSubmit)}>
              <div className="contact-submission px-1 px-lg-5">
                <input type="text" 
                  className="form-control" 
                  placeholder="Name" 
                  {...register("name", {
                      required: "Name is required",
                  })}
                />
                  {errors.name && (<span className="error">{errors.name.message}</span>)}
                <input type="text" 
                  className="form-control" 
                  placeholder="Email" 
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
                  {errors.email && (<span className="error">{errors.email.message}</span>)}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone number"
                  {...register("phone_number", {
                    required: "Phone number is required",
                    pattern: {
                      //   value:  /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
                      // value: /^[0-9]{10,12}$/,
                      value:  /^(?:\+\d{1,3}[. -]?)?(\([2-9]\d{2}\)|[2-9]\d{2})[. -]?[2-9]\d{2}[. -]?\d{4}$/,
                      message: "Please enter a valid phone number",
                    },
                  })}
                />
                  {errors.phone_number && (<span className="error">{errors.phone_number.message}</span>)}
                <textarea
                  className="form-control"
                  placeholder="Your message"
                  rows="5"
                  cols="80"
                  {...register("message", {
                    required: "Message is required",
                  })}
                ></textarea>
                  {errors.message && (<span className="error">{errors.message.message}</span>)}
                
                
                <button className="btn btn-primary form-control">Send</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </section>

      <section className="location-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="location-inner-body">
                <div className="embed-responsive embed-responsive-1by1">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1188.5989917085983!2d-76.61429374314943!3d39.40502533574698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c80fb69de01d3f%3A0xbc6c6067c722c3a8!2s534%20Park%20Ave%2C%20Towson%2C%20MD%2021204%2C%20USA!5e0!3m2!1sen!2sin!4v1691131543732!5m2!1sen!2sin"
                    className="embed-responsive-item"
                    style={{border:0}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="location-overlayer">
                  <div className="col-md-12">
                    <h6>Get in Touch</h6>
                    <h4>Find Us</h4>
                    <p className="location-text-sec">
                    We are located in the heart of Towson, Maryland, just outside of Baltimore City
                    </p>
                  </div>

                  <div className="col-md-12">
                    <div className="d-flex align-items-center location-mark">
                      <span className="material-symbols-outlined">distance</span>
                      <h4 className="m-0 ps-2">Location</h4>
                    </div>
                    <p className="mt-2">
                      534 Park Avenue, Towson,
                      <br /> MD 21204 USA
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SubFooter />
        </div>
      </section>
    </>
  );
}

export default Contact;
