import React from "react";
import aboutBanner from "../Assets/Images/about-banner.png";
import getonboard from "../Assets/Images/getonboard-img.png";
import phone from "../Assets/Images/phone.png";
import details from "../Assets/Images/deatails.png";
import location from "../Assets/Images/location.png";
import logo from "../Assets/Images/logo.png";
import SubFooter from "../Components/SubFooter";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <Helmet>
        <title>About | GoldieTalk</title>
        <meta
          name="description"
          content="The GoldieTalk platform was designed by specialists in senior care who recognize the tremendous benefits as well as the unique challenges of aging in place."
        />
        <meta
          name="keywords"
          content="GoldieTalk, Goldies, friendships, ageing, social connection, engaging communities, communities, connections, seniors, about us"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:title" content="About | GoldieTalk" />
        <meta
          property="og:description"
          content="The GoldieTalk platform was designed by specialists in senior care who recognize the tremendous benefits as well as the unique challenges of aging in place."
        />
      </Helmet>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <h1>
                Creating <span className="banner-text-inner">Goldie</span> Groups
                <br />
                Around the Globe
              </h1>
              <p className="px-1 px-md-5">
                The founders of GoldieTalk have a mission to create true and
                lasting human connections through real, old-fashioned
                conversations. Today’s older adults want to thrive at home, in the
                cities, towns, and neighborhoods they cherish. GoldieTalk is a
                powerful tool that brings vital social connections and engaging
                communities directly to seniors all over the world
              </p>
            </div>
          </div>
        </div>
        <div className="wrap-img">
          <img src={aboutBanner} className="img-fluid" alt="about"/>
        </div>
      </section>

      <section className="stroy-wapper">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h6>Our Story</h6>
              <h4>
                Why We Do, <br /> What We Do
              </h4>
            </div>
            <div className="col-md-9">
              <div className="px-0 px-md-4">
                <p>
                  The GoldieTalk platform was designed by specialists in senior
                  care who recognize the tremendous benefits as well as the
                  unique challenges of aging in place. We also understand that
                  older adults must navigate complex and costly industries in order
                  to maintain good health and independence. GoldieTalk was
                  created in response to these circumstances.
                </p>
                <p>
                  GoldieTalk is free to users, but the friendships made through
                  human contact are priceless. We encourage our users to
                  adventure from wherever they call home. Connect with people
                  who share your values and interests within your zip code or
                  across continents. Create or join rich and diverse communities
                  where members can talk, share information and pictures, and
                  experience the joy of connection.
                </p>
                <p>
                  Download the GoldieTalk app today. Find new friends and make
                  them goldie!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus-wrapper-body">
        <div className="container-fluid">
          <div className="text-center">
            <h6>Ready, Set, Go</h6>
            <h4>Get Onboard With Us</h4>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <img src={getonboard} className="img-fluid" alt="sign in"/>
            </div>
            <div className="col-lg-6">
              <div className="d-flex align-items-center h-100 px-0 px-md-5 details-sec">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex  align-items-center wrapp-out">
                      <div>
                        <img src={phone} className="img-fluid" alt="phone"/>
                      </div>
                      <div className="ms-0 ms-md-4">
                        <h6>Type in Your Number</h6>
                        <p className="m-0">
                          You can sign up and login using just your
                          phone number and a one-time password (OTP) Code
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center  wrapp-out mt-5">
                      <div>
                        <img src={details} className="img-fluid" alt="details"/>
                      </div>
                      <div className="ms-0 ms-md-4">
                        <h6>Enter Your Details</h6>
                        <p className="m-0">
                          Enter your name, email and other information so 
                          that others can get to know you better
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex  align-items-center  wrapp-out mt-5">
                      <div>
                        <img src={location} className="img-fluid" alt="location"/>
                      </div>
                      <div className="ms-0 ms-md-4">
                        <h6>Choose Your Location and Interests</h6>
                        <p className="m-0">
                          Knowing where you live and what you like can 
                          help us connect you to people like you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="answer-wrapper-body">
        <div className="container-fluid">
          <div className="row px-0 px-lg-4 px-md-5 pb-5">
            <div className="col-lg-4">
              <div className="px-0 px-md-5">
                <h6>Frequently Asked Questions</h6>
                <h4 className="mt-3">Answers to Your Questions</h4>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="accordion px-0 px-lg-4 px-md-5" id="myAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne">
                      <ul className="qstn-list">
                    
                      <li><span>
                        <h5> 01.</h5>
                      </span><span className="accordion-questn ms-1">
                        What is GoldieTalk?
                      </span></li> 
                      </ul>
                      <span></span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion">
                    <div className="card-body">
                      <p className="px-4 px-md-5 qstn-ans">
                        GoldieTalk is a social platform and mobile application
                        available from the <a className="link-text">App Store</a> and <a className="link-text">Google Play</a> for
                        download on a smartphone and tablet. The app is designed
                        with today’s older adults in mind, particularly those who
                        are also solo-agers. GoldieTalk makes it possible for
                        seniors to connect with other seniors, text and talk,
                        and form and join engaging conversation communities.
                        Building a profile requires a cell phone number, and
                        users are asked only a few additional questions to join
                        and start connecting with others.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo">
                     <ul className="qstn-list">
                     <li>
                     <span>
                        <h5>02.</h5>
                      </span>
                      <span className="accordion-questn">
                        What security measures are in place to protect my
                        privacy and personal information?
                      </span>
                     </li>
                     </ul>
                      
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <p className="px-4 px-md-5 qstn-ans">
                        Maintaining your privacy and security on the GoldieTalk
                        platform is critical to us. GoldieTalk verifies users
                        through their cell-phone numbers. A one-time password
                        (OTP) is sent via text to each user when each user signs
                        up for the app. We designed this app using this system
                        for ease of use and so that we know each user is
                        connected to a cell phone, similar to verification
                        required by the banking industry. Verification through a
                        cell phone is more secure than verification through an
                        email address.
                      </p>
                      <p className="px-4 px-md-5 qstn-ans">
                        When using GoldieTalk, users’ phone numbers and email
                        addresses are never revealed. All calls and texts are
                        delivered through the app. Published personal
                        information is limited to your preferred display name
                        and your location. From there, users control all
                        additional information.
                      </p>
                      <p className="px-4 px-md-5 qstn-ans">
                        The GoldieTalk app and website and data are stored on
                        AWS Global Cloud Infrastructure, which is the most
                        secure, extensive and reliable cloud platform available.
                        This is considered the gold standard in network
                        security.
                      </p>
                      <p className="px-4 px-md-5 qstn-ans">
                        The free GoldieTalk app does not sell any product or service. Users
                        will never be asked for credit card or financial
                        information. Users have the ability to report other
                        users and they can also deactivate their accounts at any
                        time. Please refer to our <Link to='/privacypolicy' onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Privacy Policy</Link> and <Link to='/termsofservice' onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Terms of Service</Link> for
                        additional information, and please email
                        hello@GoldieTalk.com with any additional questions on
                        privacy and security.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                    >
                    <ul className="qstn-list">
                     <li>
                      <span>
                        <h5>03.</h5>
                      </span>
                      <span className="accordion-questn">
                        What is the minimum age requirement to join GoldieTalk?
                      </span></li>
                      </ul>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <p className="px-4 px-md-5 qstn-ans">
                        GoldieTalk requires that all users must be 18 years old.
                        The GoldieTalk platform is designed for seniors to
                        connect with other seniors, but we also know important
                        multigenerational friendships and connections are to all
                        humans. At GoldieTalk, we encourage family members,
                        caregivers, and senior care professionals to create a
                        free profile. GoldieTalk is also an excellent platform
                        for mentees and mentors to network and have meaningful
                        conversations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                    >
                       <ul className="qstn-list">
                     <li>

                      <span>
                        <h5>04.</h5>
                      </span>
                      <span className="accordion-questn">
                        Can we send pictures and documents to other people?
                      </span>
                      </li>
                      </ul>
                    </button>
                  </h2>

                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <p className="px-4 px-md-5 qstn-ans">
                      The GoldieTalk app allows users to attach photos, documents and internet links via the text field. Just click the camera icon or link icon within the text field to attach images, documents, and internet links.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                    >
                       <ul className="qstn-list">
                     <li>
                      <span>
                        <h5>05.</h5>
                      </span>
                      <span className="accordion-questn">
                        Can we add other people to the communities?
                      </span>
                      </li>
                      </ul>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <p className="px-4 px-md-5 qstn-ans">
                        Community creators can add members to their communities.
                        GoldieTalk communities can be private or public. In
                        private communities, users must request to join and the
                        community administrator must approve before users can
                        join. In public communities, any GoldieTalk user can
                        join.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                    >
                       <ul className="qstn-list">
                     <li>
                      <span>
                        <h5>06.</h5>
                      </span>
                      <span className="accordion-questn">
                        Are there any additional features or benefits
                        specifically designed for older adults?
                      </span>
                      </li>
                      </ul>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#myAccordion"
                  >
                    <div className="card-body">
                      <p className="px-4 px-md-5 qstn-ans">
                        Today’s seniors are at risk for social isolation, and
                        one-third will experience loneliness in life. Many
                        seniors today live outside of their countries of origin
                        or have limited mobility, which reduces their
                        opportunities to network with others. GoldieTalk is a
                        place where all older adults can form friendships, engage in
                        meaningful conversations, and find communities that
                        support their interests and passions. This technology
                        allows for seniors all over the globe to connect, text
                        and talk in any language – all from the safety of the
                        place they call home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <SubFooter />
        </div>
      </section>
    </>
  );
}

export default About;
