import React from "react";
import logo from "../Assets/Images/logo.png";
import SubFooter from "../Components/SubFooter";
import { Helmet } from "react-helmet-async";
import { Link } from "react-scroll";


function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | GoldieTalk</title>
        <meta
            name="description"
            content="Privacy Policy explains how GoldieTalk collects, uses, and discloses information about you. The terms “GoldieTalk,” “we,” “us,” and “our” include Versally, Inc. and our affiliates."
        />
        <meta
            name="keywords"
            content="Privacy Policy, GoldieTalk Policy, GoldieTalk, Collection Of Information, Use Of Information, Sharing Of Information, Additional Details,Your Choices, Data Rights, Data Retention, Session cookies, Persistent cookies"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:title" content="Privacy Policy | GoldieTalk"/>
        <meta property="og:description" content="Privacy Policy explains how GoldieTalk collects, uses, and discloses information about you. The terms “GoldieTalk,” “we,” “us,” and “our” include Versally, Inc. and our affiliates." />
      </Helmet>
      <div className="container privacy-wrapper mt-5">
        <>
          <p>
            <span class="main-title" id="privacypolicy">
              Privacy Policy
            </span>
          </p>
          <p class="last-updated">
            <em>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                Effective:&nbsp;
              </span>
            </em>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              July 28, 2023&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}>

              Our Privacy Policy explains how GoldieTalk collects, uses, and
              discloses information about you. The terms “GoldieTalk,” “we,”
              “us,” and “our” include Versally, Inc. and our affiliates. We use
              the terms “member,” “you,” and “your” to mean any person using our
              Platform or attending related events, including any organization
              or person using the Platform on an organization’s behalf. This
              Privacy Policy applies to the processing of information about
              Users and other individuals that we collect when you use our
              “Platform,” which means any website, application, or services we
              offer, or when you communicate with us. For information about
              choices you may have under this policy, please see “Your Choices”
              below. This Privacy Policy does not apply to the information that
              you may provide to third parties, such as Users, and others with
              whom you may share information about yourself. For more
              information about the scope of our Privacy Policy, please
              see&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-4-4"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
              // duration={500}
            >
              <span
                style={{ fontSize: 18, lineHeight: "133%", color: "#1155CC" }}
              >
                Section 4.4.
              </span>
            </Link>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              To make this Privacy Policy easier to understand, we have provided
              summaries below. These summaries are not part of the official
              policy. Even though we have provided summaries, it is important
              for you to read the entire policy carefully. If you wish to access
              any particular section of this Privacy Policy, use the list below
              to jump to that section.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>1.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-1"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Collection Of Information
              </span>
            </Link>
            <span style={{ fontSize: 18,  }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>2.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-2"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Use Of Information
              </span>
            </Link>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>3.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-3"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Sharing Of Information
              </span>
            </Link>
            <span style={{ fontSize: 18,  }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>4.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-4"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Additional Details
              </span>
            </Link>
            <span style={{ fontSize: 18,  }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>5.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-5"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Your Choices
              </span>
            </Link>
            <span style={{ fontSize: 18,  }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>6.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-6"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Data Rights
              </span>
            </Link>
            <span style={{ fontSize: 18, }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>7</span>
            <span style={{ fontSize: 18, }}>.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-7"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                California Privacy Rights
              </span>
            </Link>
            <span style={{ fontSize: 18,  }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>8</span>
            <span style={{ fontSize: 18, }}>.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-8"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>Contact Us</span>
            </Link>
            <span style={{ fontSize: 18, }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontSize: 18,  }}>9.&nbsp;</span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-9"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span style={{ fontSize: 18,  }}>
                Cookie Policy
              </span>
            </Link>
          </p>
          <ul class="privacy-ul">

          <li>
       
          <p>
            <span  id="section-1" className="d-flex sub-title">
              <span className="qstn-num">1.</span>Collection Of Information&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span style={{ fontSize: 18, color: "#5D6C79" }}>
                  Summary:&nbsp;
                </span>
              </em>
            </strong>
            <em>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                We collect information about you when you:&nbsp;
              </span>
            </em>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              1. <em>Create an account;&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              2. <em>Use the Platform;&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              3. <em>Visit our website; and&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              4. <em>Communicate with us.&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                We also collect information, such as usage statistics, by using
                cookies, server logs, and similar technologies.&nbsp;
              </span>
            </em>
          </p>
          </li>
        <li>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                If you elect to use certain features, we also collect
                information through other sources, such as social media
                services, invitations, and imported contacts.&nbsp;
              </span>
            </em>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p>
            <strong>
              <span  className="d-flex sub-paragraph">
                <span className="qstn-num">1.1 </span>Information You Provide to Us&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p
            
          className="">
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We collect information that you provide directly to us. For
              example, we collect information that you provide when you create
              an account, choose interests or communities, fill out a form, or
              communicate with us. The types of information that we may collect
              include identifiers, such as your name, username, password, email
              address, postal address, and phone number; demographic information
              and any other personal information that you choose to provide,
              such as photos, choices of interests, gender, date of birth,
              communities, and professional or employment-related information,
              including your role, company, and years of experience. Certain
              information about yourself, such as gender, is optional to
              provide. Your choice of communities is also optional, but we are
              required to process this information in order to administer your
              account, and to indicate that you are a member of the communities
              that you join. See&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-2"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#1155CC" }}
              >
                Section 2
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              &nbsp;to learn how we will use the information that you provide to
              personalize our recommendations and other content to you.
              &nbsp;<br></br>On occasion, the information that you give us when you join a
              community may imply information about your beliefs, political
              views, or your health conditions. This, and other kinds of
              sensitive information, is given special protection in accordance
              with applicable law.
            </span>
          </p>
          </li>
          </ul>


          <ul class="privacy-ul">
            <li>
          <p>
            <strong>
              <span
                 className="d-flex sub-paragraph"
              >
               <span className="qstn-num">1.2</span>Information We Collect Automatically When You Use the
                Platform&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              When you use our Platform, we automatically collect internet and
              other electronic network activity information about you and other
              information, including:&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              1. <strong >Log Information</strong>: We collect log information
              about your use of the Platform, including the type of browser that
              you use; the time, duration, and frequency of your access;
              Platform pages viewed; your IP address; and the page you visited
              before visiting our Platform.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              2. <strong >Device Information</strong>: We collect information
              about the computer or mobile device that you use to access our
              Platform, including the hardware model, operating system and
              version, unique device identifiers, and mobile network
              information.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              3. <strong>Geolocation Information</strong>: We may collect
              information about the location of your device each time you use
              our Platform based on your consent to the collection of this
              information, such as when you enable location services. &nbsp;For
              further information see&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-5-2"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#1155CC" }}
              >
                Section 5.2
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              . &nbsp;We may also collect information, such as your IP address,
              that can sometimes be used to approximate your location based on
              your device settings.&nbsp;
            </span>
          </p>
          </li>
         <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              4.{" "}
              <strong>
                Information Collected by Cookies and Other Tracking Technologies
              </strong>
              : We and our service providers collect information using various
              technologies, including cookies and pixel tags (which are also
              called clear GIFs, web beacons, or pixels). Cookies are small data
              files stored on your hard drive or in device memory that help us
              improve our Platform and your experience, and track usage of our
              Platform. Pixel tags are electronic images that may be used in our
              Platform or emails, and track usage of our Platform and
              effectiveness of communications. You can learn more about the
              types of cookies we and our service providers use by reading
              our&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-9"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#00A2C7" }}
              >
                Cookie Policy
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              .&nbsp;
            </span>
          </p>

          </li>
          
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              5. <strong>Other Information</strong>: Based on your consent, we
              may collect other information from your device, such as photos
              from your camera roll or calendar information you want to manage
              via the Platform.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
                <span className="qstn-num">1.3</span>Information We Collect From Other Sources
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may collect information about you from other sources, such as
              through certain features on the Platform you elect to use. We rely
              on these third parties to either have your consent or ensure that
              they are otherwise legally permitted or required to disclose your
              information to us. Examples include:&nbsp;
            </span>
          </p>
          </li>
         
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              1. <strong>Social Media Services</strong>. If you register or log
              into your account through a third-party social media service, we
              will have access to some of your third-party account information
              from that service, including identifiers, such as your name and
              other information in that account, and used for purposes such as
              improving your GoldieTalk recommendations. We may also have access
              to third-party account information if you use the Platform feature
              to share a link.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              2. <strong>Member Content</strong>. We may receive information
              about you when you or another member uploads photos or posts other
              content to the Platform. For further details about the rights over
              this information available to individuals, see&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-6"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "133%", color: "#1155CC" }}
              >
                Section 6
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              .&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              3. <strong>Current or Future Corporate Affiliates</strong>. We may
              receive information about you from our current or future corporate
              parents, subsidiaries, or affiliates.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              4. <strong>Information From Other Third-Party Sources</strong>. In
              order to provide you with more tailored recommendations, we may
              obtain information about you from publicly and commercially
              available sources, such as third-party publishers, advertising
              networks, and service providers that we use to manage ads on our
              and other sites, and other third parties as permitted by law. For
              more information about the data that we obtain from these
              providers, please contact us at hello@GoldieTalk.com.
            </span>
          </p>
          </li>
        
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "115%", color: "#5D6C79" }}
            >
              We may analyze and make inferences from the information we
              collect.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul mt-4">
            <li>
          {/* <p class="privacy-ul sub-title">
            <span class="sub-title " id="section-2"
            >
              <span className="qstn-num">2. </span>Use Of Information&nbsp;
            </span>
          </p> */}
          <p class="sub-title mt-4"><span className="qstn-num">2.</span>Use Of Information&nbsp;</p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span style={{ fontSize: 18, color: "#5D6C79" }}>
                  Summary:&nbsp;
                </span>
              </em>
            </strong>
            <em>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                We use information about you to: &nbsp;
              </span>
            </em>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
             <span className="qstn-num">1.</span>
              <em>
                Operate, improve, promote, and protect GoldieTalk and our
                Platform;&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
             <span className="qstn-num">2. </span><em>Communicate with you; and&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              3. <em>Personalize content.&nbsp;</em>
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">

          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                <span className="qstn-num">2.1 </span>Operating our Platform&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We may use information about you described above for various
              business purposes related to operating our Platform, including to:
              &nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              1. Provide, maintain, and improve our Platform, develop new
              products and services (for example, including you in tests or
              pilots of new or revised Platform functions, such as messaging or
              search functionality or using information about your usage of the
              Platform to develop new or revised messaging or search
              functionality), and manage the performance of our Platform;&nbsp;
            </span>
          </p>
          </li>
         <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              2. Display information about you, for instance, your list of
              interests, which will be visible on your profile;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              3. Personalize the Platform, for example, to suggest content in
              which we think you may be interested;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              4. Allow you to share information about your use of the Platform
              through a third-party social media service, if you connect your
              account through that service (which you can turn off at any time)
              and to provide you with better recommendations;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              5. Monitor and analyze trends, usage, and activities in connection
              with our Platform;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              6. Detect, investigate, and prevent fraudulent transactions,
              abuse, and other illegal activities; to protect the rights,
              property, or safety of GoldieTalk and others; to enforce
              compliance with our policies; and to comply with applicable law
              and government requests; and&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "115%", color: "#5D6C79" }}
            >
              7. Perform accounting and administrative tasks, and enforce or
              manage legal claims.&nbsp;
            </span>
          </p>
          </li>
          </ul>

      
          <ul class="privacy-ul">
            <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "226%", color: "#5D6C79" }} className="d-flex"
              >
               <span className="qstn-num">2.2 </span> Communicating With You&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              We may use information about you for various business purposes
              related to communicating with you, including to:&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              1. Respond to your comments, questions, and requests, and provide
              customer service;
            </span>
          </p>
          </li>
          <li>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              2. Communicate with you, in accordance with your account settings,
              about products, services, and events offered by us and others, to
              provide news and information that we think will be of interest to
              you, to conduct online surveys, to contact you about events on our
              Platform that are being held near your location, and to otherwise
              communicate with you in accordance with Section 2.3;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              3. Notify you about communications of other Users, in accordance
              with the communication preferences that you indicate in your
              account; and&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              4. Send you technical notices, updates, security alerts and
              support, and administrative messages.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
             <span className="qstn-num">2.3 </span>Advertising And Other Uses&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              We may use information about you for various other business
              purposes, including to:&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              1. Provide content, features, or sponsorships that match member
              profiles or interests &nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              2. Deliver more relevant advertising to you on and off the
              Platform;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              3. Facilitate contests and other promotions;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              4. Send you marketing communications, subject to applicable
              law;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              5. Combine with information that we collect for the purposes
              described in this Privacy Policy; and&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              6. Carry out any other purposes described to you at the time that
              we collected the information.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
               <span className="qstn-num">2.4 </span>Legal Basis for Processing&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              If you are in a jurisdiction that requires a specific legal basis
              for processing personal data, our legal basis for collecting and
              using the information described above will depend on the type of
              information and the specific context in which we collect it.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              1. We process information about you in order to provide our
              services in accordance with our&nbsp;
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Terms of Service
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              , for example, to allow you to join communities or to display your
              profile to other Users, or to allow us to send you important
              service updates.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              2. We also process information about you where it is in our
              legitimate interests to do so and not overridden by your rights
              (for example, in some cases for direct marketing, fraud
              prevention, network and information systems security, responding
              to your communications, the operation of networks of communities
              by the network administrators, and improving our Platform).&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              3. Sometimes you provide us with sensitive information about you;
              for instance, the mere fact of joining a certain community may
              indicate information about your health, religion, political views,
              or sexual identity. Joining such communities or posting
              information on the Platform about these topics is entirely
              voluntary and, depending on the settings of the community and your
              profile, our legal basis for processing any sensitive information
              is either that you manifestly make it public or your explicit
              consent.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              4. In some cases, we may also have a legal obligation to process
              information about you, or may otherwise need the information to
              protect your vital interests or those of another person. For
              example, we may process information, if we believe doing so is
              required by, or in accordance with, applicable law, regulation,
              legal process, or audits.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p class="sub-title mt-4"><span className="qstn-num">3.</span>Sharing Of Information&nbsp;
            
          </p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span
                  style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
                >
                  Summary:&nbsp;
                </span>
              </em>
            </strong>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                We do not share information about you except as described in
                this Privacy Policy. We may disclose personal information about
                you, including:&nbsp;
              </span>
            </em>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "115%", color: "#5D6C79" }} className=""
            >
            <span className="">1. </span>{" "}
              <em>
                to Users or social media services, depending on how you engage
                with the Platform. &nbsp;Information that is public on our
                platform is accessible to others both on and off the
                Platform;&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "115%", color: "#5D6C79" }}
            >
             <span className="">2. </span>{" "}
              <em>
                to our third-party service providers who help us to operate or
                improve our Platform;&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              3.{" "}
              <em>
                when we work with third parties for analytics and to deliver
                targeted advertising to you on and off the Platform. &nbsp;For
                more information, please see our&nbsp;
              </em>
            </span>
            <em>
              <Link
                style={{ cursor: "pointer" }}
                to="section-9"
                // smooth={true}
                offset={-100} // Adjust this offset to match your header height
              >
                <span
                  style={{ fontSize: 18, lineHeight: "134%", color: "#00A2C7" }}
                >
                  Cookie Policy
                </span>
              </Link>
            </em>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                ;&nbsp;
              </span>
            </em>
          </p>
          </li>
        <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              4.{" "}
              <em>
                to authorities to comply with legal obligations or requests and
                to protect and defend our rights and property; &nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              5.{" "}
              <em>
                to an affiliate or successor entity or otherwise as part of a
                corporate transaction;&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              6.{" "}
              <em>among our businesses and affiliated companies; and&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              7.{" "}
              <em>
                when you direct us to share your information. &nbsp;&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                We may also share information in a manner that does not identify
                you, such as de-identified or aggregated information. Such
                information is not subject to this Privacy Policy.&nbsp;
              </span>
            </em>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
               <span className="qstn-num">3.1 </span>Through Our Platform&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We share some of the information that we collect by displaying it
              on our Platform according to your member profile and account
              settings. Some information, such as username, is always public.
              Some information, such as interests, is public by default. Some
              information, such as community affiliations, will always be
              visible to other Users of that GoldieTalk community, and may be
              public, depending on the settings of that community. We recommend
              that you check the community settings and what information will be
              available before joining the community to ensure that you are
              happy with the information that is visible to others.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "217%", color: "#5D6C79" }}
            >
              Information that is public on our Platform is visible to others on
              and off the Platform.
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                <span className="qstn-num">3.2</span>With Our Service Providers&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may use service providers in connection with operating and
              improving the Platform to assist with certain business purposes,
              such as email transmission, conducting surveys or contests, data
              hosting, managing our ads, and some aspects of our technical and
              customer support. These service providers, which include data
              analytics providers (as discussed further in Section 3.4 below),
              may access, process, and store information about you on our behalf
              for the purposes we authorize, subject to confidentiality
              obligations.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
              <span className="qstn-num">3.3 </span>With our Analytics and Advertising Partners&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may share information we have collected about you with others
              or allow others to use technologies (like cookies, web beacons,
              and device identifiers) that collect information about your use of
              the Platform and other websites and online services, to provide
              analytic services and to serve ads for GoldieTalk. See our&nbsp;
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Cookie Policy
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              &nbsp;for details about these technologies and the information
              that they collect, use, or share, including how you may be able to
              control or disable these services.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Depending on where you live, you may have the right to opt-out of
              the sharing of your personal information for purposes of targeted
              advertising (targeting ads based on consumer activity across other
              sites and services). GoldieTalk provides you the opportunity to
              opt-out of this sharing, as described in Section 5 below.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p >
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
               <span className="qstn-num">3.4 </span> Following The Law And Protecting GoldieTalk&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may access, preserve, and disclose information about you to
              government entities and other third parties, including the content
              of messages, if we believe disclosure is in accordance with, or
              required by, applicable law, regulation, legal process, or audits.
              We may also disclose information about you if we believe that your
              actions are inconsistent with our&nbsp;
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Terms of Service
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              &nbsp;or related guidelines and policies, or if necessary to
              protect the rights, property, or safety of, or prevent fraud or
              abuse of, GoldieTalk or others. &nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" } } className="d-flex">
               <span className="qstn-num">3.5 </span>Social Sharing And Other Tools&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              The Platform may offer social sharing features and other
              integrated tools which let you share actions that you take on our
              Platform with third-party services, and vice versa. Such features
              let you share information with your friends or the public,
              depending on the settings you have chosen with the service. The
              third-party services' use of the information will be governed by
              the third parties’ privacy policies, and we do not control the
              third parties’ use of the shared data. For more information about
              the purpose and scope of data collection and processing in
              connection with social sharing features, please review the privacy
              policies of the services that provide these features.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p >
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
               <span className="qstn-num">3.6</span>Affiliate Sharing And Merger, Sale, Or Other Asset
                Transfers&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              If GoldieTalk is involved in a merger, acquisition, financing,
              reorganization, bankruptcy, or sale of our assets, information
              about you may be shared, sold, or transferred as part of that
              transaction. We may also share information about you with current
              or future corporate parents, subsidiaries, or affiliates.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
               <span className="qstn-num">3.7 </span> Other Situations&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We may de-identify or aggregate information about you and share it
              freely, so that you can no longer be identified. Such information
              is not subject to this Privacy Policy.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may also share information about you with your consent or at
              your direction or where we are legally entitled to do so. For
              example, your information may be shared with event sponsors and
              others as described to you when you RSVP to an event. &nbsp;Your
              information also is made available to other community Users and
              event organizers when you RSVP for and participate in an event.
              &nbsp;
            </span>
          </p>
          </li>
          <li>
          <p className="sub-title mt-4">
            <span id="section-4">
             <span className="qstn-num sub-title mt-4">4.</span>Additional Details&nbsp; 
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span style={{ fontSize: 18, color: "#5D6C79" }}>
                  Summary:&nbsp;
                </span>
              </em>
            </strong>
          </p>
          </li>
          <li>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              1.{" "}
              <em>
                GoldieTalk is based in and governed by U.S. law and we will be
                processing, transferring, and storing information in and to the
                U.S. and other countries.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }} className=""
            >
             <span className="">2. </span>{" "}
              <em>
                We have implemented technical and organizational measures to
                protect your personal information. We cannot guarantee these
                controls will provide absolute security.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }} className="d-flex"
            >
              <span className="">3. </span>{" "}
              <em>
                This Privacy Policy is periodically updated, and does not apply
                to third parties.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
              <span className="qstn-num">4.1 </span>Transfer Of Information To The U.S. And Other
                Countries&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                GoldieTalk is based in the United States
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              . Information about you will be transferred to, and processed in,
              the U.S. and countries other than the country in which you are
              resident. These countries may have data protection laws that are
              different to the laws of your country and, in some cases, may not
              be as protective. We have taken appropriate safeguards, such as
              standard contractual clauses, to require that your information
              will remain protected in accordance with this Privacy
              Policy.&nbsp;
            </span>
          </p>
          </li>
         <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
               <span className="qstn-num">4.2 </span> Security&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              We employ technical and organizational measures designed to
              protect your information that is under our control and that we
              process on your behalf from unauthorized access, collection, use,
              disclosure, copying, modification, or disposal, both during
              transmission and once we receive it. For example, we store all
              information that you provide to us on servers on which security
              controls are used. We train employees regarding our data privacy
              policies and procedures, and authorize employees to access
              personal information as required for their role. We use firewalls
              designed to protect against intruders and test for network
              vulnerabilities.
            </span>
          </p>
          </li>
          <li>
          <p >
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                <span className="qstn-num">4.3 </span>Data Retention&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              We may temporarily de-activate your account if you have not logged
              in for six months or more. You may contact us if you wish to
              reactivate your account.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We retain certain information that we collect from you while you
              visit or are a member of the Platform, and in certain cases where
              you have deleted your account, for the following business
              purposes:&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
             <span>1. </span> So you can use our Platform;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
             <span> 2.</span> To ensure that we do not communicate with you if you have asked
              us not to;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              <span>3. </span>To provide accurate accounting information to other Users about
              the communities that they organize or administer, and associated
              Userships;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              <span>4. </span>To better understand the traffic to our Platform so that we can
              provide all Users with the best possible experience;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
             <span>5. </span>To detect and prevent abuse of our Platform, illegal activities
              and breaches of our Terms of Service; and&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
            <span>6. </span>To comply with applicable legal, tax or accounting
              requirements.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              When we have no ongoing legitimate business need to process your
              information, we will either delete it or take steps to de-identify
              or anonymize it in accordance with applicable law.
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 11, color: "black" }}>&nbsp;</span>
          </p>
          </li>
          <li>
          <p>
            <strong id="section-4-4">
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                <span className="qstn-num">4.4 </span> Policy Scope&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              This Privacy Policy does not apply to information that you have
              provided or made accessible to third parties, such as other Users,
              including community organizers and network administrators, and
              others with whom you may share information about yourself. Our
              Platform may direct you to a third-party service, such as social
              media services, or a portion of our Platform may be controlled by
              a third party (typically through the use of a frame or pop-up
              window separate from other content on our Platform). Disclosure of
              information to these third parties is subject to the relevant
              third party’s privacy policy. We are not responsible for the
              third-party privacy policy or content, even if we link to those
              services from our Platform or if we share information with these
              third parties.&nbsp;
            </span>
          </p>
          </li>
          <li> 
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Users, including community organizers and network administrators,
              are directly responsible for complying with all requirements of
              applicable privacy laws in connection with the information that
              they obtain and process for the purposes of managing their
              contacts, organizing and communicating with communities, or
              administering networks.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
              <span className="qstn-num">4.5 </span> Revisions To This Policy&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may modify this Privacy Policy from time to time. When we do,
              we will provide notice to you by publishing the most current
              version and revising the date at the top of this page. Depending
              on the circumstances, we may provide additional notice to you of
              changes to this Privacy Policy, such as by sending you an email or
              by displaying a prominent notice on our Platform.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p className="sub-title mt-4">
            <span  id="section-5">
              <span className="qstn-num">5.</span>Your Choices&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span style={{ fontSize: 18, color: "#5D6C79" }}>
                  Summary:&nbsp;
                </span>
              </em>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              1.{" "}
              <em>
                You can review and change information about you or deactivate
                your account in your account settings, but we may retain certain
                information.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              2. <em>You can remove photo tags if you wish.&nbsp;</em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              3.{" "}
              <em>
                Consult your device settings for information about how to
                deactivate location-based features at any time.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              4.{" "}
              <em>
                You have the right to opt out of receiving GoldieTalk’s
                promotional messages, but you may still receive other
                non-promotional communications.
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
               <span className="qstn-num">5.1 </span>Your Choices: Account Information&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              You may update or correct your account information by editing your
              account settings or by sending a request to hello@GoldieTalk.com
              as described in&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-6"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#1155CC" }}
              >
                Section 6
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              &nbsp;and&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-6"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#1155CC" }}
              >
                Section 7
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              . You may deactivate your account by editing your account
              settings. You may also request deletion of your information as
              described in&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-6"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#1155CC" }}
              >
                Section 6
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              &nbsp;and&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-7"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#1155CC" }}
              >
                Section 7
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              . &nbsp;You will also be able to choose what information others
              see about you and who may contact you by using the Privacy
              Settings section in your Account.&nbsp;
            </span>
          </p>
          </li>
          </ul>

          <ul class="privacy-ul">

          <li>

        
         
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} id="section-5-2" className="d-flex">
               <span className="qstn-num">5.2 </span> Your Choices: Location Information&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              When you first access the Platform, depending on your device
              settings, we will collect information about your location, which
              we use to make better recommendations for users in your area, and
              to improve our Platform.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Note that our Platform or its features may no longer function
              properly if you change your settings.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Your mobile device settings may also give you the option to choose
              whether to allow us to view your geolocation on a continuous
              basis, only when using the app, or never. Allowing us to view your
              geolocation when you are not using the app allows us to provide
              you with recommendations on a regular basis. Please refer to your
              device’s guide for additional information on how to adjust
              location services.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} id="section-5-3">
                <span className="qstn-num">5.3 </span>Your Choices: Cookies&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We may use and allow others to use cookies, web beacons, device
              identifiers, and other technologies to collect information about
              your use of the Platform and other websites and online services.
              See our&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-9"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#00A2C7" }}
              >
                Cookie Policy
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              &nbsp;for details about these technologies and the information
              that they collect, use, or share, including how you may be able to
              control or disable these services.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              As stated above in Section 3.3, depending on where you live, you
              may have the right to opt out of the sharing or processing of your
              personal information for purposes of targeted advertising
              (targeting ads based on consumer activity across other sites and
              services), and you may be able to use your Cookie Settings to
              select cookies you would like to opt opt out of, including those
              used for targeted advertising purposes.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
          <li>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }} className="d-flex">
                <span className="qstn-num">5.4 </span>Your Choices: Promotional Communications&nbsp;
              </span>
            </strong>
          </p>
          </li>
          <li>
      
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              You can control some of the messages that you receive from
              GoldieTalk and other Users by selecting the unsubscribe link in
              the message that you receive, or by adjusting the communication
              preferences in your account settings. We will also send you a link
              to these settings when you first sign up and in subsequent
              messages. If you opt out, we may still send you non-promotional
              messages, such as those about your account or our ongoing business
              relations. In addition, please consult the privacy policies of
              other Users for information about how to control or opt out of
              promotional messages from them off the Platform.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
          <li>
          <p className="sub-title mt-4">
            <span  id="section-6">
             <span className="qstn-num">6.</span>Data Rights&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span style={{ fontSize: 18, color: "#5D6C79" }}>Summary:</span>
              </em>
            </strong>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              1.{" "}
              <em>
                You may have the right to exercise data protection rights,
                including to request that GoldieTalk deletes, amends, or
                provides you access to your information.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
            <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              2.
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#666666" }}
            >
              &nbsp;
              <em>
                You may have the right to opt out of the sharing or processing
                of your personal information for the purposes of targeted
                advertising.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              3.{" "}
              <em>
                You have the right to opt out of receiving GoldieTalk’s
                promotional messages, but you may still receive other
                communications.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              4.{" "}
              <em>
                You can complain to your local data protection authority about
                the use of your information.&nbsp;
              </em>
            </span>
          </p>
          </li>
          <li>
          <p
            s >
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We respond to all requests that we receive from individuals who
              wish to exercise their data protection rights in accordance with
              applicable data protection laws. You can contact us with your
              request by sending an email to hello@GoldieTalk.com.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                Opting out of the sharing of your personal information for
                targeted advertising
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              . To exercise your right to opt-out of these kinds of advertising,
              please follow the instructions found in the “Opting Out” section
              of our&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-9"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#00A2C7" }}
              >
                Cookie Policy&nbsp;
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              and as described in&nbsp;
              <Link
                style={{ cursor: "pointer" }}
                to="section-5-3"
                // smooth={true}
                offset={-100} // Adjust this offset to match your header height
              >
                <span
                  style={{ fontSize: 18, lineHeight: "129%", color: "#00A2C7" }}
                >
                  Section 5.3&nbsp;
                </span>
              </Link>
              above.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                Objecting to processing
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              &nbsp;of your information, asking us{" "}
              <strong>to restrict processing</strong> of your information,{" "}
              <strong>or requesting the portability</strong> of your
              information.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Opting out from receiving marketing communications
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              &nbsp;that we send you at any time. You can exercise this right by
              selecting the “unsubscribe” or “opt-out” link in the marketing
              emails we send you. Additionally, you may update your email
              preferences by changing the settings in your account.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Withdrawing your consent
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              &nbsp;at any time if we have collected and processed your
              information with your consent. Withdrawing your consent will not
              affect the lawfulness of any processing that we conducted prior to
              your withdrawal, nor will it affect processing of your information
              conducted in reliance on lawful processing grounds other than
              consent.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Complaining to a data protection authority
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              &nbsp;about our collection and use of your information. For more
              information, please contact your local data protection
              authority.&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Note that GoldieTalk is a controller of your information.
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We will not discriminate against you for exercising your rights
              and choices, although some of the functionality and features
              available on the Platform may change or no longer be available to
              you. &nbsp;&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p className="sub-title mt-4">
            <span  id="section-7" className="d-flex">
             <span className="qstn-num">7. </span>California Privacy Rights&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              During the preceding 12 months, GoldieTalk has collected, used,
              and shared the categories of personal information described above
              in this Privacy Policy. We do not consider the ways we share your
              information as a “sale” for purposes of California law. California
              residents have certain rights to access, correct and delete their
              personal information and to request transparency about our privacy
              practices. &nbsp;If you would like to access or delete your
              information, or otherwise exercise your rights under the CCPA,
              please see&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="section-6"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#1155CC" }}
              >
                Section 6
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              . Once we receive your request, we may verify it by requesting
              information sufficient to confirm your identity, including by
              asking you for additional information. If you choose to exercise
              your rights, we will not charge you different prices or provide
              different quality of services for exercising your rights unless
              those differences are permitted by law. &nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              If you are a California resident and would like to use an agent
              registered with the California Secretary of State to exercise your
              rights, we may request evidence that you have provided such agent
              with power of attorney or that the agent otherwise has valid
              written authority to submit requests to exercise rights on your
              behalf. &nbsp;&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                Do-Not-Track Signals
              </span>
            </em>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              . Some mobile and web browsers transmit "do-not-track" signals.
              Because of differences in how web browsers incorporate and
              activate this feature, it is not always clear whether users intend
              for these signals to be transmitted, or whether they even are
              aware of them. We currently do not take action in response to
              these signals.&nbsp;
            </span>
          </p>
          </li>
          </ul>
          <ul class="privacy-ul">
            <li>
          <p className="sub-title mt-4">
            <span  id="section-8">
            <span className="qstn-num">8.</span>Contact Us&nbsp;
            </span>
          </p>
          </li>
          <li>
          <p>
            <strong>
              <em>
                <span
                  style={{ fontSize: 18, lineHeight: "217%", color: "#5D6C79" }}
                >
                  Summary:&nbsp;
                </span>
              </em>
            </strong>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "217%", color: "#5D6C79" }}
              >
                If you have any questions, you can contact us at
                hello@GoldieTalk.com.&nbsp;
              </span>
            </em>
          </p>
          </li>
         
          <li>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "217%", color: "#5D6C79" }}
              >
                &nbsp;
              </span>
            </em>
          </p>
          </li>
          </ul>
          {/* <p
    style={{
      margin: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Poppins",sans-serif'
    }}
  >
    <span style={{ fontSize: 11 }}>
      6/30/23 Cookie Policy – GoldieTalk&nbsp;
    </span>
  </p> */}

          <p class="main-title">
            <span  id="section-9">
              Cookie Policy
            </span>
          </p>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              Last Updated July 28, 2023<strong>&nbsp;</strong>
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              As described in our&nbsp;
            </span>
            <Link
              style={{ cursor: "pointer" }}
              to="privacypolicy"
              // smooth={true}
              offset={-100} // Adjust this offset to match your header height
            >
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#00A2C7" }}
              >
                Privacy Policy
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              , we and our partners use cookies and other technologies to
              provide our Platform and to enhance your experience. This Cookie
              Policy sets out some further detail on how and why we use these
              technologies on our Platform, which includes any website,
              application, or service we offer. The terms "GoldieTalk," "we,"
              "us," and "our" include Versally, INC and our affiliates. &nbsp;
            </span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                Cookies
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                What Cookies Are.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Cookies are small data files which are downloaded to your device
              when you visit a website either for the duration of your visit (a
              ‘session cookie’) or for repeat visits (a ‘persistent cookie’).
              Cookies are then sent back to the originating website on each
              subsequent visit, or to another website that recognizes that
              cookie. Cookies are useful because they allow us to recognize your
              browser or device, which helps in the efficient operation of our
              services. Other similar files such as clear GIFs, web beacons and
              pixel tags work in the same way and we use the word ‘cookie’ in
              this policy to refer to all files that collect information in this
              way. A cookie cannot read data off your hard drive or read cookie
              files created by other websites.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Cookies set by the website operator are called "
              <strong>first party cookies</strong>". Cookies set by parties
              other than the website operator are called "
              <strong>third party cookies</strong>". The parties that set third
              party cookies can recognize your web browser both when it visits
              our Platform and when it visits certain other websites where the
              third party’s cookies are also present. Data collected pursuant to
              first party cookies can also be shared with third parties.&nbsp;
            </span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                How We Use Cookies&nbsp;
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Cookies serve a variety of functions, like enabling us to remember
              certain information you provide to us as you navigate between
              pages on the Platform. We use cookies for the following
              purposes:&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Authentication:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We need to use cookies to enable you to log into secure areas of
              our Platform. In addition, we may employ third-party services that
              may use cookies to help you sign into their services from our
              Platform.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Services, features, and preferences:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We use cookies to provide functionality and help us deliver our
              products and services according to your preferences in the
              following ways.&nbsp;
            </span>
          </p>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                To facilitate communications:&nbsp;
              </span>
            </em>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We automatically receive information about communications sent and
              received using our Services.&nbsp;
            </span>
          </p>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                To remember your settings and preferences:&nbsp;
              </span>
            </em>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We use cookies to recognize your browser and remember your
              preferences (such as your preferred language). &nbsp;
            </span>
          </p>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                To improve performance:&nbsp;
              </span>
            </em>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              We use cookies to provide the best experience. For example, we use
              cookies to speed up the loading of our Services. &nbsp;
            </span>
          </p>
          <p>
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                To process payments:&nbsp;
              </span>
            </em>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Our third-party payment processors, Stripe and PayPal, may use
              cookies as part of the payment flow.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                Security:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              We use cookies to support or enable security features we have
              deployed, and to help us detect malicious activity and violations
              of our Terms of Service. These cookies help us prevent fraudulent
              use of login credentials.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
              >
                Performance, Analytics and Research:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              We use cookies to help us analyze how the Platform is being
              accessed and used, and enable us to track performance of the
              Platform. This helps us to understand, improve, and research
              features and content on the Platform. We may also use other
              services, such as Google Analytics or other third-party cookies
              and related technologies, to assist with analyzing performance on
              our Platform. As part of providing these services, these partners
              may use cookies and the technologies described below to collect
              and store information about your device, such as time of visit,
              pages visited, time spent on each page of the Platform, links
              clicked and conversion information, IP address, browser, mobile
              network information, and type of operating system used.
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                Advertising:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We and our third party advertising partners use cookies to display
              ads to you on our Platform and on third party websites or online
              services, to make those ads more relevant to your interests, and
              to analyze the effectiveness of ad campaigns. Our first party
              cookies record your visit to our Platform, the pages you have
              visited, and the links you have followed, so that we can share
              relevant ads about GoldieTalk’s services and other products with
              you on our Platform and on third party websites or online
              services. Our advertising partners include advertisers,
              advertising service providers and third party publishers. They may
              collect cookie and other information about your activity across
              different websites, including our Platform, and across your
              different devices to infer your interests, develop personalized
              content and deliver ads that may be relevant to you. &nbsp;
            </span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                Duration of Cookies
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Some cookies, known as "<strong>session cookies</strong>", will
              stay on your device only for as long as you are accessing our
              Platform. Others, known as "<strong>persistent cookies</strong>",
              are stored on your device for longer periods.&nbsp;
            </span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                Other Technologies
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Use of Other Technologies.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              We may allow others to provide analytics services and serve
              advertisements on our behalf. In addition to the uses of cookies
              described above, these entities may use other methods, such as the
              technologies described below, to collect information about your
              use of the Platform and other websites and online services.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Pixels tags.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Pixel tags (which are also called clear GIFs, web beacons, or
              pixels), are small pieces of code that can be embedded on websites
              and emails. Pixels tags may be used to learn how you interact with
              our site pages and emails, and this information helps us and our
              partners provide you with a more tailored experience.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                Device Identifiers.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              A device identifier is a unique label that can be used to identify
              a mobile device. Device identifiers may be used to track, analyze
              and improve the performance of the Platform and ads
              delivered.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Local storage.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Local storage is an industry-standard technology that enables the
              storage and retrieval of data on a computer, mobile phone or other
              device. Local storage may be used to store member preferences on
              devices.
            </span>
          </p>
          <p>
            <span style={{ fontSize: 11 }}>&nbsp;</span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                Cookie Preferences&nbsp;
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Depending on where you are located, you will have different
              options to control or opt out of the use of cookies.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                Cookie Consent Management.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              For users in certain jurisdictions, such as the European Economic
              Area and the UK, we only use non-technically necessary cookies
              with your consent. If you are accessing our Platform from one of
              those countries, when you first land on our Platform you will be
              asked which cookies you consent to using. &nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              If you are accessing our Platform from another location, by using
              our Platform you consent to storage and access to cookies and
              other technologies on your device, in accordance with this Cookie
              Policy. &nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              Users in most jurisdictions can revise their choices at any time
              through the Cookie Settings. &nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Note that because this choice is a cookie-based mechanism, there
              are limitations on whether it applies across devices or on other
              browsers. &nbsp;&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
              >
                Device and Web Browser Settings.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              All users can control the use of cookies by following the
              instructions below: &nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              Most devices and web browsers are set to accept cookies by
              default. If you prefer, you may be able to remove or reject
              cookies. You can learn more about how to block cookies by visiting
              all aboutcookies.org. Please note that if you choose to remove or
              reject cookies, this could affect the availability and
              functionality of our Platform.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Third parties that use cookies and other tracking technologies to
              deliver targeted advertisements may offer you a way to prevent
              such targeted advertisements by opting out at the websites of
              industry groups such as the Digital Advertising Alliance, or if
              located in the European Union, the European Interactive Digital
              Advertising Alliance. You may also be able to control advertising
              cookies provided by publishers, for example Google's Ad Preference
              Manager. Your device may also include a feature that allows you to
              opt out of having certain information collected through apps used
              for behavioral advertising purposes. On a mobile device, this
              information is typically available under the “settings”
            </span>
            <span style={{ fontSize: 11, lineHeight: "132%" }}>&nbsp;</span>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              function. For iOS devices, to limit interest-based ad tracking, go
              to Settings &gt; Privacy &gt; Advertising &gt; Turn on “Limit Ad
              Tracking.” For Android devices, go to Settings &gt; Google
              services &gt; Ads &gt; Turn on “Opt out of Ads Personalization."
              For iOS and Android, you can also reset the advertising identifier
              that is currently assigned to you. Note that even if you choose to
              opt out of receiving targeted advertising by a third party these
              tracking technologies may still collect data for other purposes
              including analytics and you will still see ads from us across the
              Internet, but the ads will not be targeted based on behavioral
              information about you and may therefore be less relevant to you
              and your interests.&nbsp;
            </span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                Revisions to this Policy&nbsp;
              </span>
            </strong>
          </p>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may modify this Cookie Policy from time to time. When we do, we
              will provide notice to you by publishing the most current version
              and revising the date at the top of this page. Depending on the
              circumstances, we may provide additional notice to you of changes
              to this Cookie Policy, such as by sending you an email or
              displaying a prominent notice on our Platform. By continuing to
              use the Platform after any changes come into effect, you agree to
              the revised Cookie Policy.&nbsp;
            </span>
          </p>
          <p class="sub-title">
            <strong>
              <span >
                How to Contact Us&nbsp;
              </span>
            </strong>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "134%", color: "#5D6C79" }}
            >
              If you wish to contact us with any queries or concerns, please
              contact us at hello@GoldieTalk.com.&nbsp;
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              lineHeight: "129%",
              fontSize: 15,
              fontFamily: '"Poppins",sans-serif',
              marginTop: "5.95pt",
              marginRight: "88.2pt",
              marginBottom: ".0001pt",
              marginLeft: "9.3pt",
              textIndent: "-.45pt",
            }}
          >
            <span style={{ fontSize: 11, lineHeight: "129%" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0cm",
              lineHeight: "217%",
              fontSize: 15,
              fontFamily: '"Poppins",sans-serif',
              marginTop: "19.7pt",
              marginRight: "37.1pt",
              marginBottom: ".0001pt",
              marginLeft: "9.35pt",
              textIndent: "-.9pt",
              border: "none",
            }}
          >
            <em>
              <span
                style={{ fontSize: 18, lineHeight: "217%", color: "#5D6C79" }}
              >
                &nbsp;
              </span>
            </em>
          </p>
        </>

        <SubFooter />
      </div>
    </>
  );
}

export default PrivacyPolicy;
