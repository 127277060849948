import React from 'react';
import footerLogo from "../Assets/Images/footer-logo.png";
import appleLogo from "../Assets/Images/apple-logo.png";
import playstoreLogo from "../Assets/Images/playstore-logo.png";
import { Link } from 'react-router-dom';

function SubFooter() {
  return (
    <>
        <div className="footer-wrapper">
            <div className="row">
              <div className="col-lg-3 col-12">
                <div className="footer-inner-wrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="footer-logo">
                        <img className="" src={footerLogo} alt='logo'/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <nav className="mt-1 footer-link">
                        <ul class="footer-menu">
                          <li className="me-4"><Link to="/" onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Home</Link></li>
                          <li className="me-4"><Link to="/about" onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>About</Link></li>
                          <li><Link to="/contact" onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Contact</Link></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-12 p-0">
                <div className="row align-items-center">
                  <div className="col-lg-11 col-12 p-0">
                    <div className="footer-inner-text">
                      <h4 className="text-white mt-lg-0 mt-3 me-1 me-lg-0">
                        Join the
                      </h4>
                      <h4 className="text-white mt-3 m-0">
                        <span className="text-goldie">Goldie</span> Family
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-1 col-12">
                    <div className="footer-inner-btn">
                      <a className="ms-2 mt-lg-0 mt-3" href="https://apps.apple.com/in/app/goldietalk/id6451487106" target="_blank">
                        <img src={appleLogo} alt='apple logo'/>
                      </a>
                      <a className="ms-2 mt-3 " href="https://play.google.com/store/apps/details?id=com.goldietalk.app" target="_blank">
                        <img src={playstoreLogo} alt='playstore logo'/>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="footer-address-sec">
                      <ul className="footer-inner-address">
                        <li className="">
                          <span className="material-symbols-outlined me-2">
                            mail
                          </span>
                          hello@GoldieTalk.com
                        </li>
                        <a href="tel:8554225544">
                          <li className="ms-0 ms-md-3">
                            <span className="material-symbols-outlined me-2">
                              call
                            </span>
                            (855) 422-5544
                          </li>
                        </a>
                        <li className="ms-0 ms-md-3">
                          <span className="material-symbols-outlined me-2">
                            pin_drop
                          </span>
                          534 Park Avenue, Towson, MD 21204 USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </>
  );
}

export default SubFooter