import React from 'react'
import SubFooter from './SubFooter';
import { Link } from 'react-router-dom';

function Footer() {
    const currentYear = new Date().getFullYear();

  return (
    <>
     <footer>
        <div className="container">

          <div className="footer-bottom">
          <div className="row">
            <div className="col-12 col-lg-4">
              <ul>
                <li className="me-4"><Link to='/privacypolicy' onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Privacy Policy</Link></li>
                <li><Link to='/termsofservice' onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Terms & Conditions</Link></li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 order-1 order-lg-0">
              <ul className="copy-right">
                <li>Copyright © GoldieTalk {currentYear} </li>
              </ul>
            </div>
            <div className="col-12 col-lg-4  order-0 order-lg-1">
              <ul>
                <li className="me-3"><a href='https://www.facebook.com/profile.php?id=61552258762875' target='_blank'>Facebook</a></li>
                <li className="me-3"><a href='https://www.instagram.com/goldietalkapp/' target='_blank'>Instagram</a></li>
                <li className="me-3"><a href='https://www.twitter.com/goldie_talk' target='_blank'><i class="fa-brands fa-x-twitter"></i></a></li>
                <li className="me-3"><a href='https://www.linkedin.com/company/goldietalk' target='_blank'>LinkedIn</a></li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </footer>
    </>
  )
}

export default Footer