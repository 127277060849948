import React from "react";
import banner from '../Assets/Images/banner-bg.png'
import logo from '../Assets/Images/logo.svg'
import ios from '../Assets/Images/ios.png'
import android from '../Assets/Images/android.png'
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <>
      <section className="position-relative">
  <img src={banner} alt="banner" className="img-fluid body-banner" />
  <div className="container">
    <nav className="navbar navbar-expand-lg navbar-light top-navbar">
      <div className="container-fluid">
        <a className="navbar-brand"><img className="img-fluid" src={logo} alt="logo" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 nav-ui">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/about">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/contact">Contact</NavLink>
            </li>
            <li className="nav-btn">
              <a href="https://apps.apple.com/in/app/goldietalk/id6451487106" target="_blank">
                <div className="dwld-app ms">
                  <div className="me-2"><img src={ios} alt="ios"/></div>
                  <div className="dwl-btn">
                    <p>Download on</p>
                    {/* <p>Launching soon</p> */}
                    <h6>App Store</h6>
                  </div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.goldietalk.app" target="_blank">
                <div className="dwld-app">
                  <div className="me-2"><img src={android} alt="android"/></div>
                  <div className="dwl-btn">
                    <p>Get it on</p>
                    {/* <p>Launching soon</p> */}
                    <h6>Play Store</h6>
                  </div>
                </div>
              </a>
            </li> 
          </ul>
        </div>
      </div>
    </nav>
  </div>
</section>
    </>
  );
}

export default Navbar;
