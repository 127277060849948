import React from "react";
import logo from "../Assets/Images/logo.png";
import SubFooter from "../Components/SubFooter";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function TermsOfService() {
  return (
    <>
      <Helmet>
        <title>Terms of Service | GoldieTalk</title>
        <meta
            name="description"
            content="The Terms of Service includes information about your legal rights and covers areas such as limitations of liability, resolution of disputes by mandatory arbitration rather than a judge or jury in a court of law, and a class action waiver."
        />
        <meta
            name="keywords"
            content="Terms of Service, GoldieTalk Terms, GoldieTalk, GoldieTalk Communities, Agreement, Account and usership, Content and Privacy, Use of Our Platform, Indemnification, Warranty Disclaimer, Limitations of liability, Dispute Resolution, Intellectual Property, Eligibility, Modification, Suspension,Termination, Account Information, Security, License to the GoldieTalk Platform"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:title" content="Terms of Service | GoldieTalk"/>
        <meta property="og:description" content="The Terms of Service includes information about your legal rights and covers areas such as limitations of liability, resolution of disputes by mandatory arbitration rather than a judge or jury in a court of law, and a class action waiver." />
      </Helmet>
      <div className="container mt-5">
        <>
          {/* <p
    style={{
      margin: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Arial",sans-serif',
      border: "none"
    }}
  >
    <span style={{ fontSize: 16 }}>
      7/28
      <span style={{ color: "black" }}>
        /23, &nbsp;Terms of Service –&nbsp;
      </span>
      GoldieTalk
    </span>
  </p> */}
  <p >
            <span class="main-title" id="privacypolicy">
            Terms of Service
            </span>
          </p>

          {/* <p
            style={{
              margin: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Arial",sans-serif',
              marginTop: "16.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "6.55pt",
              border: "none",
            }}
          >
            <span style={{ fontSize: 21, color: "#2E3E48" }}>
              Terms of Service
            </span>
          </p> */}
          <p
            style={{
              margin: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              marginTop: "12.95pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "6.45pt",
              border: "none",
            }}
          >
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              Terms of Service Overview
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              lineHeight: "132%",
              fontSize: 15,
              marginTop: "13.65pt",
              marginRight: ".7pt",
              marginBottom: ".0001pt",
              marginLeft: "6.3pt",
              textIndent: ".75pt",
              border: "none",
            }}
          >
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Below is an overview of our Terms of Service for our “Platform”,
              which means any website, application, or service we offer. You
              should read the complete Terms of Service because that document
              (and not this overview) is our legally binding agreement.{" "}
              <strong>
                <em>
                  The Terms of Service includes information about your legal
                  rights and covers areas such as limitations of liability,
                  resolution of disputes by mandatory arbitration rather than a
                  judge or jury in a court of law, and a class action
                  waiver.&nbsp;
                </em>
              </strong>
            </span>
          </p>



          <p class="sub-title" >
                Your Relationship with GoldieTalk
          </p>

          
          <p class="wrp-paragraph">
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              By using our Platform, you are agreeing to our Terms of Service.
              The Terms of Service are a legally binding agreement between you
              and GoldieTalk. If you break the rules, we may suspend or
              terminate your account.&nbsp;
            </span>
          </p>
          <p class="sub-title" >
                GoldieTalk Communities &nbsp;
          </p>
          <p class="wrp-paragraph">
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              Any GoldieTalk user can create a community and establish it as
              open to “Anyone” or “By Approval”. Any GoldieTalk user can join a
              community that is open to Anyone. While there is probably a
              GoldieTalk community out there for everyone, not every GoldieTalk
              community is for you. If you can’t find the right community, you
              can easily start your own community.&nbsp;
            </span>
          </p>
          <p class="wrp-paragraph">
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              Using the GoldieTalk platform, though not involving person to
              person meetings in real places, is still about meeting real people
              you connect with on the phone or through texting. Consequently,
              you are responsible for your behavior as you interact with real
              people through phone calls and text messages.&nbsp;
            </span>
          </p>
          <p class="sub-title" >Your Content and Content of Others</p>
          <p class="wrp-paragraph">
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              You are responsible for your “Content”, which means any
              information, material, or other content posted to our Platform.
              Your Content must comply with our Terms of Service. Your Content
              is also subject to our Intellectual Property Dispute
              Policies. We do not own the Content that you post. However, we do require
              that you provide us a license to use this Content in order for us
              to operate, improve, promote, and protect GoldieTalk and our
              Platform for the benefit of you and others. 
              We are not responsible for Content that users post or the
              communications that users send using our Platform. We generally
              don’t review Content before it’s posted. If you see Content that
              violates our Terms of Service, you may report inappropriate
              Content to us
            </span>
          </p>

          <p class="sub-title">
                Our Platform
          </p>
          <p class="wrp-paragraph">
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              We try hard to make sure that our Platform is always available and
              working, but we cannot guarantee it will be. Occasionally things
              may not go exactly as planned. We apologize in advance for any
              inconvenience. We are continually improving our Platform. This means that we may
              modify or discontinue portions of our Platform. By using our
              Platform, you agree to the limitations of liability and release in
              our Terms of Service. You also agree to resolve any disputes you
              may have with us in the manner described in the Terms of Service.
              Unless you are based in the European Union, you agree to mandatory
              arbitration and waive your right to seek relief from a judge or
              jury in a court of law. Claims can only be brought individually,
              and not as part of a class action. You may be based in a country where the laws that apply provide
              for different or additional rights and obligations. These Terms of
              Service will point out areas of difference.
            </span>
          </p>
         
       
       
          <p class="sub-title">
                Terms of Service
          </p>
          <p class="last-updated">
              Last Updated: 7/28/23&nbsp;
          </p>
        
          <p class="wrp-paragraph">
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Our Terms of Service contain important information about your
              legal rights. To make it easier to understand, we have provided
              summaries and tips in these gray boxes. These summaries are not
              part of the official terms, and it is important for you to read
              the language in each section carefully.&nbsp;
            </span>
          </p>
          <p class="inner-titlt">
                1. This Agreement&nbsp;
          </p>
          <p >
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}>
                Summary:
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              You agree to follow some basic rules when using GoldieTalk’s
              Platform. These rules are described in these Terms of Service,
              including the related policies and guidelines discussed below. We
              may change these rules.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                1.1 The Agreement.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              GoldieTalk enables you and other users to connect GoldieTalk
              directly through calls or texts or in communities through texts.
              The terms “GoldieTalk,” “we,” “us,” and “our” include GoldieTalk,
              our corporate parent, affiliates, or subsidiaries. We use the
              terms “you” and “your” to mean any person using our Platform, and
              any organization or person using the Platform on an organization’s
              behalf. We use the word “Platform” to mean any website,
              application, or service offered by GoldieTalk, including content
              we offer and electronic communications we send. We provide our
              Platform to you subject to these Terms of Service. We use the
              terms “Terms of Service” and “Agreement” interchangeably. Your use
              of the Platform signifies that you agree to this Agreement. If you
              are using the Platform for an organization, you agree to this
              Agreement on behalf of that organization, and represent you have
              authority to bind that organization to the terms contained in this
              Agreement. If you do not or are unable to agree to this Agreement,
              do not use our Platform.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Tip:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              We have included summaries and tips to provide an overview of some
              legal concepts and answer common questions, but the text of these
              summaries and tips are for your convenience only and do not have a
              legal effect.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Tip:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              These Terms of Service are an agreement between you and GoldieTalk
              and not between you and any other user.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                1.2 Revisions to this Agreement.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              We may modify this Agreement from time to time. When we do, we
              will provide notice to you by publishing the most current version
              and revising the date at the top of this page. If we make any
              material change to this Agreement, we will provide additional
              notice to you, such as by sending you an email or displaying a
              prominent notice on our Platform. By continuing to use the
              Platform after any changes come into effect, you agree to the
              revised Agreement. If you do not wish to accept the revised
              Agreement, you can close your account.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                2. Your Account and usership&nbsp;
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              You need to be at least 18 years old to use our Platform.
              GoldieTalk users control the Content and usership of their
              GoldieTalk communities. However, we may remove any Content you
              post or terminate your account at any time.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                2.1 Eligibility.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Our Platform is available to anyone who is at least 18 years old.
              You represent that you are at least 18. Additional eligibility
              requirements for a particular portion of our Platform may be set
              by any user who has the ability to moderate or manage that portion
              of our Platform. For example, the eligibility requirements for a
              GoldieTalk community may be set by the user of that
              community.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                2.2 Modification, Suspension, and Termination of Your
                Account.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              We may modify, suspend, or terminate your account or access to the
              Platform if, in our sole discretion, we determine that you have
              violated this Agreement, including any of the policies or
              guidelines that are part of this Agreement, that it is in the best
              interest of the GoldieTalk community, or to protect our brand or
              Platform. When this happens, we will notify you of the reasons for
              the modification, suspension, or termination. We also may remove
              accounts of users who are inactive for an extended period of time.
              Please email hello@GoldieTalk.com if you believe the modification,
              suspension, or termination has occurred in error.
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                2.3 Account Information and Security.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              When you register, you provide us with some basic information,
              including your name and your phone number. To set-up the account,
              you will use a one-time-password, a code sent to your phone. This
              is how we verify your account - pairing your name with your phone
              number.
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                2.4 License to the GoldieTalk Platform and Services
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              . Subject to your compliance with this Agreement, GoldieTalk
              grants you a limited, non-exclusive, revocable, non-sublicensable,
              non-transferable right to use the Platform in order to access and
              use the services and features that we make available to you.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4" >
                3. Your Content and Privacy&nbsp;
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              You are responsible for the Content you post on our Platform or
              send to us. You give us a license to use this Content to operate,
              improve, promote, and protect GoldieTalk and our Platform.
              Our&nbsp;
            </span>
            <Link to="/privacypolicy" onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#00A2C7" }}
              >
                Privacy Policy
              </span>
            </Link>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              &nbsp;explains how we collect and use information you provide
              about yourself and the information that may be associated with
              you, and explains the limited ways we may share this
              information.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                3.1 Your Content.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              You are responsible for the Content that you post to the Platform
              or otherwise provide to GoldieTalk. We use the word “Content” to
              mean the information, material, and any other content that you
              post to the Platform or otherwise send to us. Examples of your
              Content include:   The material that users typically post to the Platform, such as
              information about GoldieTalk communities and GoldieTalk events,
              comments, and photos; All feedback, suggestions, and other
              communications that you send or direct to GoldieTalk.
            </span>
          </p>
          <p
            style={{
              margin: "0cm",
              lineHeight: "129%",
              fontSize: 15,
              marginTop: "10.9pt",
              marginRight: "20.4pt",
              marginBottom: ".0001pt",
              marginLeft: "26.65pt",
              textIndent: "-.2pt",
              border: "none",
            }}
          >
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
            
            </span>
          </p>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              By being responsible for your Content, you agree, among other
              things, that:&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              You have all the permissions, rights, and licenses needed
              (including under copyrights, trademarks, contract rights, privacy
              rights, or publicity rights) to provide the Content to the
              Platform and to GoldieTalk;
            </span>
          </p>
          <p>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              Your Content does not include personal, private or confidential
              information belonging to others; and&nbsp;
            </span>
          </p>
          <p
          >
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              Your Content does not otherwise violate the rights of any
              individual or entity.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              You also agree that you and your Content comply with this
              Agreement. You also agree that we may remove the metadata
              associated with your Content.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                Tip:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Search engines can see public areas of the Platform. Your Content
              within these areas, such as the name and location that you provide
              during registration, and your usership in public GoldieTalk
              communities, may appear in search results. Our Platform is
              designed so that search engines cannot see your Content that is
              within restricted areas of the Platform, such as private
              GoldieTalk communities.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
              >
                3.2 Content License from You.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              We do not claim ownership of your Content. However, to enable us
              to operate, improve, promote, and protect GoldieTalk and our
              Platform, and to ensure we do not violate any rights you may have
              in your Content, you hereby grant GoldieTalk a non-exclusive,
              worldwide, perpetual, irrevocable, royalty-free, sublicensable,
              transferable right and license (including a waiver of any moral
              rights) to use, host, store, reproduce, modify, publish, publicly
              display, publicly perform, distribute, and create derivative works
              of, your Content and to commercialize and exploit the copyright,
              trademark, publicity, and database rights you have in your
              Content. This license would permit your Content to remain on the
              Platform, even after you cease to be a user of a GoldieTalk
              community or of the Platform.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Tip:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              This license doesn’t give us ownership of any of your Content. It
              simply gives us and users the right to use it on or related to the
              Platform. This license continues even if you close your account,
              because it’s necessary for us to operate the Platform.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                3.3 Privacy.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              GoldieTalk collects registration and other information about you
              through our Platform. Please refer to our&nbsp;
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#00A2C7" }}
            >
              Privacy Policy
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              &nbsp;and&nbsp;
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#00A2C7" }}
            >
              Cookie Policy
            </span>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              &nbsp;for details on how we collect, use, and disclose this
              information. These policies do not govern use of information that
              you provide to third parties, such as to users of GoldieTalk’s
              Platform.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Tip:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              GoldieTalk has no control over how other users may use information
              that you provide to them, so you should exercise common sense and
              good judgment when sharing information with others on our
              Platform.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                4. Your Use of Our Platform&nbsp;
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              We require that you follow our policies and guidelines when using
              our Platform. We have no responsibility for Content that anyone
              posts to our Platform.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                4.1 Our Policies, Guidelines and Applicable Laws.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              When you use our Platform, you agree to comply with all applicable
              laws, rules and regulations, and to not violate or infringe the
              rights of any third party.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              In addition, your access to and use of some areas or features of
              the Platform may be subject to additional terms, policies,
              standards or guidelines (“Additional Terms”). You may be required
              to accept these Additional Terms before you can access these
              Platform areas and features. If there is a conflict between the
              Terms of Service and the Additional Terms, those Additional Terms
              will govern your access to and use of that Platform area or
              feature, unless otherwise specified in the Additional Terms.&nbsp;
            </span>
          </p>
          <p >
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              When the Platform uses third party services to provide certain
              features and services for our users, our users may be required to
              comply with the terms of service that apply to these features and
              services. For example, some areas of our Platform feature mapping
              services provided by Google Maps and Google Earth. When you use
              these mapping services on the Platform, you agree to be bound by
              the Google Maps/Google Earth Additional Terms of Service.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              If you do not comply, we may modify, suspend, or terminate your
              account or access to the Platform, in our sole discretion, and we
              will provide you with reasons for the modification, suspension, or
              termination. Please email hello@GoldieTalk.com if you believe the
              modification, suspension, or termination has occurred in error.
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                4.2 Content of Others.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              GoldieTalk does not control the Content of other users. When we
              become aware of inappropriate Content on our Platform, we reserve
              the right to investigate and take appropriate action, but we do
              not have any obligation to monitor, nor do we take responsibility
              for, the Content of other users.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                4.3 Interactions with Others.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              GoldieTalk is not a party to any offline arrangements made through
              our Platform. GoldieTalk does not conduct or require background
              checks on users and does not attempt to verify the truth or
              accuracy of statements made by users. GoldieTalk makes no
              representations or warranties concerning the conduct or Content of
              any users or their interactions with you.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                Tip:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              Exercise common sense and good judgment when using our Platform
              and interacting with other users, both on our Platform and at
              GoldieTalk events. If you have a concern regarding other users,
              you can report it to hello@GoldieTalk.com.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
              >
                4.4 Prohibited Uses of the Platform.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              Our Platform contains proprietary and confidential information and
              is protected by intellectual property and other laws. Unless we
              expressly permit it through this Agreement, you agree that you
              will not, either directly or indirectly (a) use, host, store,
              reproduce, modify, publish, publicly display, publicly perform,
              distribute, or create derivative works of the Platform, or any
              portion of the Platform; (b) remove or alter the proprietary
              notices on the Platform; (c) reverse engineer, disassemble,
              decompile, or attempt to discover the source code or structure,
              sequence, and organization of the Platform; and (d) rent, lease,
              resell, distribute, or use the Platform for commercial purposes
              that are not contemplated by this Agreement. You also agree that
              you will not use the Platform to solicit or collect (i) personal
              data from others except as necessary for the administration of or
              participation in a community or (ii) sensitive personal data, as
              defined or treated as such under applicable law (including, by way
              of example only, health information and social security numbers
              and other government identifiers). In addition, you agree that you
              will comply with the export control laws of your local
              jurisdiction. You also represent and warrant that you are not
              located in a country that is subject to a U.S. government embargo
              or that has been designated by the U.S. government as a “terrorist
              supporting” country, and that you are not listed on any U.S.
              government list of prohibited or restricted parties. If you are a
              developer working with our API, you should review our API License
              Terms.&nbsp;
            </span>
          </p>
          <p >
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                4.5 Platform Safety and Security.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              You agree that you will not, either directly or indirectly, (a)
              extract data from the Platform for a commercial purpose not
              permitted by these Terms of Service, whether through use of an
              automated system or software, and whether operated by a third
              party or otherwise (“screen scraping,” “data scraping,” or “web
              scraping”); (b) engage in any activity that interferes with or
              disrupts, that is designed to interfere with or disrupt, or
              imposes undue burdens on the Platform or its systems.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              You agree to use, retain, and otherwise process personal data
              collected from the Platform in accordance with applicable laws,
              rules, and regulations and solely for purposes of administering
              and participating in GoldieTalk communities. Without limitation,
              you agree to provide notice to individuals about your processing
              of their personal data, to obtain any necessary consents, and to
              respond to requests made by individuals as required by applicable
              law. You also agree to safeguard such data from unauthorized
              access or processing. You must delete such personal data once it
              is no longer strictly necessary for the administration of a
              GoldieTalk community or GoldieTalk event or promptly following a
              request from GoldieTalk, and you will promptly certify to such
              deletion. &nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                4.6 Platform Modifications.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              We work hard to continuously improve our Platform. This means that
              we may modify or discontinue portions or all of our Platform with
              or without notice and without liability to you or any third
              party.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                4.7 Third Party Sites and Services.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              The Platform contains links to third party sites, and is
              integrated with various third party services, applications and
              sites that may make available to you their content and products.
              We don’t control these third parties and aren’t responsible for
              those sites or services or their content or products. These third
              parties may have their own terms and policies, and your use of
              them will be governed by those terms and policies. You do not have
              a license to use the intellectual property of third parties merely
              by way of your access to our Platform.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                5. Release
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              To the full extent permitted by applicable law, you agree not to
              hold us responsible for anything that happens related to
              transactions with third parties, user interactions, or in
              connection with a GoldieTalk community. You also agree not to hold
              users responsible for their negligence in connection with their
              Content or a GoldieTalk community
            </span>
          </p>
         
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "133%", color: "#5D6C79" }}
            >
              To the full extent permitted by applicable law, you agree to
              release us and our officers, directors, shareholders, agents,
              employees, consultants, corporate parent, affiliates,
              subsidiaries, sponsors, and other third-party partners (
              “GoldieTalk Parties”) from claims, demands, and damages (direct
              and consequential) (“Claims”), arising out of or in any way
              connected with any transaction with a third party, your
              interactions with other users, or in connection with a GoldieTalk
              community. You also agree, to the full extent permitted by
              applicable law, to release users from Claims based on an
              organizer’s negligence arising out of or in any way connected with
              their Content or a GoldieTalk community. The law in some countries
              and states do not allow the release, so these limits may not apply
              to you. You waive and relinquish all rights and benefits that you
              have or may have under Section 1542 of the California Civil Code
              or any similar provision of statutory or non-statutory law of any
              other jurisdiction to the fullest extent permitted by law.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                6. Indemnification&nbsp;
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              To the full extent permitted by applicable law, you agree to
              reimburse us if we get sued in connection with your use of our
              Platform.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              To the full extent permitted by applicable law, you agree to
              indemnify, defend and hold all GoldieTalk Parties harmless from
              any Claims, made by any third party due to or arising out of (a)
              your violations of this Agreement, (b) your use, misuse, or abuse
              of our Platform, (c) your Content, (d) your violation of any law,
              statute, ordinance or regulation or the rights of a third party,
              or (e) your participation or conduct in a GoldieTalk community or
              a GoldieTalk event that violates this Agreement. You agree to
              promptly notify us of any third party Claims, cooperate with all
              GoldieTalk Parties in defending such Claims, and pay all fees,
              costs and expenses associated with defending such Claims
              (including, but not limited to, attorneys’ fees). You agree not to
              settle any Claim without our prior written consent.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                7. Warranty Disclaimer and Limitation of Liability&nbsp;
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Our Platform is provided to you “as is” and we make no warranties
              of any kind with respect to our Platform.
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                7.1 Warranty Disclaimer.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Our Platform is provided to you “as is” and on an “as available”
              basis. To the full extent permitted by applicable law, we disclaim
              all warranties and conditions of any kind, including but not
              limited to statutory warranties, and the implied warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement. We also disclaim any warranties regarding (a)
              the reliability, timeliness, accuracy, and performance of our
              Platform, (b) any information, advice, services, or goods obtained
              through or advertised on our Platform or by us, as well as for any
              information or advice received through any links to other websites
              or resources provided through our Platform, (c) the results that
              may be obtained from the Platform, and (d) the correction of any
              errors in the Platform, (e) any material or data obtained through
              the use of our Platform, and (f) dealings with or as the result of
              the presence of marketing partners or other third parties on or
              located through our Platform. You may have additional rights under
              the law of the country in which you are based. You agree that the
              duration of such additional rights will be limited to the full
              extent permitted by such law.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                7.2 Limitation of Liability.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              To the full extent permitted by applicable law, you agree that in
              no event shall any GoldieTalk Parties be liable for any direct,
              indirect, incidental, special, or consequential damages, including
              but not limited to, damages for loss of profits, goodwill, use,
              data, or other intangible losses (even if any GoldieTalk Parties
              have been advised of the possibility of such damages) arising out
              of or in connection with (a) our Platform or this Agreement or the
              inability to use our Platform (however arising, including our
              negligence), (b) statements or conduct of or transactions with any
              user or third party on the Platform, (c) your use of our Platform
              or transportation to or from GoldieTalk events, attendance at
              GoldieTalk events, or participation in or exclusion from
              GoldieTalk communities, or (d) any other matter relating to the
              Platform. The limitations set forth above in this Section 7 will
              not limit or exclude liability for our gross negligence, fraud, or
              intentional, malicious, or reckless misconduct. Some laws do not
              allow the limitation or exclusion of liability, so these limits
              may not apply to you. If you are a user based in the EU, you have
              legal remedies if we cause you loss by our breach or
              non-performance of our legal obligations, or by our negligence,
              and these terms do not affect those statutory remedies.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                8. Dispute Resolution
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              If you have a dispute with GoldieTalk, you agree to try to work it
              out directly with us first. If we can’t work it out, with limited
              exceptions, you must submit any dispute with us to a neutral
              arbitrator instead of taking the claim to a court of law. Claims
              can only be brought individually, and not as part of a class
              action. Users based in the European Union may have additional or
              different rights, as provided by applicable law. GoldieTalk has no
              obligation to get involved with any disputes you have with other
              users, although we may try to facilitate a resolution.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                8.1 Informal Resolution.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Before making any claim, you and GoldieTalk agree to try to
              resolve any disputes through good faith discussions. We use the
              term “claim” in this Section 8 to mean any dispute, claim or
              controversy arising out of or relating to your use of our Platform
              or this Agreement, including your participation in GoldieTalk
              events. You or GoldieTalk may initiate this process by sending
              written notice according to Section 10.2 describing the dispute
              and your proposed resolution. In the event that we cannot resolve
              the issue within 30 business days following receipt of the initial
              notice, you or GoldieTalk may bring a claim in accordance with
              this Section 8. Users based in the European Union may have
              additional or different rights, as provided by applicable
              law.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                8.2 Arbitration Agreement.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              The arbitration procedures described in this Section 8.2 apply to
              all users, except for users who are based in the European Union.
              Section 8.6 sets out the dispute resolution procedures that apply
              to users who are based in the European Union.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                a. Mandatory Arbitration.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Except as set forth in Section 8.3, you agree to submit any claim
              to JAMS, Inc., or its successor (“JAMS”) for final and binding
              arbitration. In arbitration, certain rights that you or we would
              have in court may not be available, such as discovery or appeal.
              You and GoldieTalk are each expressly waiving any right to trial
              by judge or jury in a court of law. This agreement to arbitrate
              shall apply regardless of whether the claim arises during or after
              any termination of this Agreement or your relationship with
              GoldieTalk.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                b. Arbitration Time for Filing.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Any claim subject to arbitration must be filed within one year
              after the date the party asserting the claim first knows or should
              know of the act, omission or default giving rise to the claim, or
              the shortest time period permitted by applicable law.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                c. Arbitration Procedures.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              Either party may commence arbitration by filing a written demand
              for arbitration with JAMS, with a copy to the other party
              according to the notice procedures in Section 10.2. The
              arbitration will be conducted in accordance with JAMS Streamlined
              Arbitration Rules and Procedures and any other applicable rules
              that JAMS requires (“JAMS Rules”) that are in effect as of the
              demand for arbitration. You agree that the U.S. Federal
              Arbitration Act and federal arbitration law govern the
              interpretation and enforcement of these arbitration provisions.
              Any arbitration hearings will take place in New York County, New
              York or elsewhere as required by JAMS Rules. Your responsibility
              to pay any filing, administrative and arbitrator fees will be
              solely as set forth in the JAMS Rules. The parties will cooperate
              with JAMS and each other in scheduling the arbitration
              proceedings, and in selecting one arbitrator from the appropriate
              JAMS list with substantial experience in resolving intellectual
              property and contract disputes. The arbitrator shall follow this
              Agreement and, to the extent permitted by JAMS Rules, can award
              costs, fees, and expenses, including attorneys’ fees, to the
              prevailing party, except that the arbitrator shall not award
              declaratory or injunctive relief benefiting anyone but the parties
              to the arbitration. Judgment upon the award rendered by such
              arbitrator may be entered in any court of competent
              jurisdiction.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                8.3 Exceptions.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              You or GoldieTalk may assert claims, if they qualify, in small
              claims court in New York County, New York or any U.S. county where
              you live or work. You or GoldieTalk may seek injunctive relief
              from a court of competent jurisdiction in New York County, New
              York as necessary to protect the intellectual property rights of
              you or GoldieTalk pending the completion of arbitration.
              GoldieTalk may take action in court or arbitration to collect any
              fees or recover damages for, or to seek injunctive relief relating
              to, Platform operations, or unauthorized use of our Platform or
              intellectual property. Nothing in this Section 8 shall diminish
              GoldieTalk’s right to modify, suspend or terminate your account or
              access to our Platform under Section 2.2.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                8.4 Arbitration Opt Out. You may decline to resolve disputes
                through arbitration by emailing us at hello@GoldieTalk.com
                within 30 days of the date you first agree to this Agreement.
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              &nbsp;Your email must include your full name, residential address,
              the email address registered to your GoldieTalk account, and a
              clear statement that you want to opt out of arbitration. If you
              opt out according to this process, then Section 8.2 of this
              Agreement does not apply to you. This opt-out does not affect any
              other sections of this Agreement, such as Sections 8.3
              (Exceptions), 8.5 (Class Action Waiver), 10.5 (Governing Law),
              10.6 (Judicial Forum), and 10.7 (Time for Filing).&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                8.5 Class Action Waiver.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              You agree to resolve disputes with GoldieTalk on an individual
              basis. You agree not to bring a claim as a plaintiff or a class
              user in a class, consolidated, or representative action.{" "}
              <strong>
                You are expressly waiving any right to participate in class
                actions, class arbitrations, private attorney general actions,
                and consolidation with other arbitrations.&nbsp;
              </strong>
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                8.6 Dispute Resolution Terms Applicable to users Based in the
                EU.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              If you are a user based in the European Union, the mandatory
              provisions of the law of the country in which you are resident
              will apply. Nothing in this Agreement will affect your rights as a
              consumer to rely on the mandatory provisions of the local law that
              applies. If you are based in the European Union, the local laws in
              your European Union user State may allow you to take legal action
              against GoldieTalk in your user State and to invoke certain local
              laws against GoldieTalk. The European Commission provides for an
              online dispute resolution platform.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                9. Intellectual Property
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                Summary:&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              If you use GoldieTalk’s trademark, be sure to follow our Trademark
              Usage Guidelines. Also, don’t infringe on anyone’s intellectual
              property. If you believe your intellectual property is being
              infringed somewhere on the GoldieTalk Platform, please follow the
              procedures in our Intellectual Property Dispute Policies.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                9.1 Intellectual Property of GoldieTalk.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              GoldieTalk trademarks, logos, service marks, and service names are
              the intellectual property of GoldieTalk. Our Trademark Usage
              Guidelines explain how you may and may not use them. Our Platform,
              including our material on the Platform, are also our or our
              licensors’ intellectual property. Except as described in the
              Trademark Usage Guidelines, you agree not to use our intellectual
              property without our prior written consent.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                9.2 Intellectual Property of Others.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              GoldieTalk respects the intellectual property of others, and we
              expect our users to do the same. We may, in appropriate
              circumstances and in our discretion, remove or disable access to
              material that we believe may infringe on the intellectual property
              rights of others. We may also restrict or terminate access to our
              Platform to those who we believe to be repeat infringers. If you
              believe your intellectual property rights have been violated,
              please review our Intellectual Property Dispute Policies.&nbsp;
            </span>
          </p>
          <p class="inner-titlt mt-4">
                10. General Terms
          </p>
          <p>
            <strong>
              <span style={{ fontSize: 18, color: "#5D6C79" }}>
                Summary:&nbsp;
              </span>
            </strong>
            <span style={{ fontSize: 18, color: "#5D6C79" }}>
              This section contains terms we have added for miscellaneous
              purposes. Please continue to read this section carefully.&nbsp;
            </span>
          </p>
          <p >
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.1 Translation.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              This Agreement was written in English. It was then translated into
              other languages. If there is any inconsistency between the English
              version and a translated version, the English language version
              controls.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                10.2 Notices.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              Except as otherwise stated in this Agreement or as expressly
              required by law, any notice to us shall be given by certified
              postal mail to Versally, Inc., Attn: Legal Department,6007 Hunt
              Ridge Rd. #3332 Baltimore, MD 21210, or by email to
              hello@GoldieTalk.com. Any notice to you shall be given to the most
              current email address in your account.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                10.3 Entire Agreement.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              This Agreement constitutes the entire agreement between you and
              GoldieTalk, superseding any prior agreements between you and
              GoldieTalk on such subject matter.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.4 No Agency.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              No agency, partnership, joint venture, employee-employer or
              franchiser-franchisee relationship between you and GoldieTalk is
              intended or created by this Agreement. A user of the GoldieTalk
              Platform is not GoldieTalk's representative or agent, and may not
              enter into an agreement on GoldieTalk’s behalf.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                10.5 Governing Law.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              This Agreement and the relationship between you and GoldieTalk
              shall be governed by the laws of the State of Maryland without
              regard to its conflict of laws provisions, except as provided in
              Section 9.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
              >
                10.6 Judicial Forum.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "132%", color: "#5D6C79" }}
            >
              If our agreement to arbitrate is found not to apply to you or your
              claim, or if you opt out of arbitration pursuant to Section 9.4,
              you and GoldieTalk agree that any judicial proceedings (other than
              small claims actions) must be brought exclusively in the federal
              or state courts located in Baltimore County, Maryland, and you and
              GoldieTalk agree to venue and personal jurisdiction in those
              courts.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              If you are a user based in the European Union, you may bring
              judicial proceedings against us arising from or in connection with
              this Agreement in your country of residence. In addition, for
              users based in the European Union, the European Commission
              provides for an online dispute resolution platform.
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.7 Time for Filing.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Any claim not subject to arbitration must be commenced within one
              year after the date the party asserting the claim first knows or
              should know of the act, omission or default giving rise to the
              claim, or the shortest time period permitted by applicable law.
              This clause does not apply to you if you are based in the European
              Union.&nbsp;
            </span>
          </p>
          <p >
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.8 Assignment.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              This Agreement is not assignable, transferable, or sublicensable
              by you except with GoldieTalk’s prior written consent, but may be
              assigned or transferred by us to our corporate parent or any
              affiliate or subsidiary, or in connection with a merger,
              acquisition, corporate reorganization, sale of all or
              substantially all of GoldieTalk’s assets, or similar
              transaction.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.9 No Waiver.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              A party’s failure to exercise or enforce any right or provision of
              this Agreement shall not constitute a waiver of such right or
              provision and does not waive any right to act with respect to
              subsequent or similar breaches.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.10 Severance.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              If any provision of this Agreement is found to be invalid by a
              court of competent jurisdiction, you and GoldieTalk nevertheless
              agree that the court should endeavor to give effect to the
              parties’ intentions as reflected in the provision, and that the
              other provisions of this Agreement will remain in full force and
              effect.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
              >
                10.11 Termination.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "129%", color: "#5D6C79" }}
            >
              If we terminate your account or access to our Platform, this
              Agreement terminates with respect to the user account that has
              been terminated, provided that the provisions listed in Section
              10.13 will survive such termination.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.12 Survival.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Sections 3.2 (Content License from You), 3.3 (Privacy), 5
              (Release), 6 (Indemnification), 7 (Warranty Disclaimer and
              Limitation of Liability), 8 (Dispute Resolution), and 10 (General
              Terms) of this Agreement, and any other provisions necessary to
              give effect to these provisions, shall survive any termination or
              expiration of this Agreement.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
              >
                10.13 Titles.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "131%", color: "#5D6C79" }}
            >
              The section titles in this Agreement are for convenience only and
              have no legal or contractual effect. The information in “Terms of
              Service Overview” section and in the “Tips” and “Summary” sections
              is also for convenience only and has no legal or contractual
              effect.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span
                style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
              >
                10.14 Violations.&nbsp;
              </span>
            </strong>
            <span
              style={{ fontSize: 18, lineHeight: "145%", color: "#5D6C79" }}
            >
              Please report any violations of this Agreement by a user or third
              party by sending an email to hello@GoldieTalk.com.
            </span>
          </p>
        </>

        <SubFooter />
      </div>
    </>
  );
}

export default TermsOfService;
